import { Stack, Stepper, useSteps } from "@chakra-ui/react";
import { NumberStep } from "../ui/NumberStep";

const steps: { title: string }[] = [
  { title: "Checkout" },
  { title: "Order Confirmation" },
  { title: "Instant Access" },
];

export const CheckoutSteps = () => {
  const { activeStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <div className="mx-auto mb-[50px] mt-[30px] px-4 xxxs:max-w-[340px] md:max-w-xl">
      <div className="container-1200">
        <Stack>
          <Stepper size="sm" index={activeStep} gap="0">
            {steps.map((step, index) => {
              return (
                <NumberStep
                  key={`step-${index}`}
                  index={index}
                  title={step.title}
                  isActive={index === activeStep}
                />
              );
            })}
          </Stepper>
        </Stack>
      </div>
    </div>
  );
};
