import axios from "../libs/axiosPublic";
import {
  InitiateCheckoutPayload,
  AddPaymentInfoPayload,
  LeadPayload,
  PurchasePayload,
} from "../types/type";

// Initiate checkout API
export const initiateCheckout = async (payload: InitiateCheckoutPayload) => {
  try {
    const res = await axios.post("https://main-features-repository-ygaq.onrender.com/initiate-checkout", payload);
    return res.data;
  } catch (error) {
    console.error("Error initiating checkout", error);
  }
};

// Add Payment Info API
export const addPaymentInfo = async (payload: AddPaymentInfoPayload) => {
  try {
    const res = await axios.post("https://main-features-repository-ygaq.onrender.com/add-payment-info", payload);
    return res.data;
  } catch (error) {
    console.error("Error adding payment info", error);
  }
};

// Lead API
export const lead = async (payload: LeadPayload) => {
  try {
    const res = await axios.post("https://main-features-repository-ygaq.onrender.com/lead", payload);
    return res.data;
  } catch (error) {
    console.error("Error sending lead data", error);
  }
};

// Purchase API
export const purchase = async (payload: PurchasePayload) => {
  try {
    const res = await axios.post("https://main-features-repository-ygaq.onrender.com/purchase", payload);
    return res.data;
  } catch (error) {
    console.error("Error making purchase", error);
  }
};
