import { Box } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomProgressBar from "../components/CustomProgressBar";
import Quiz from "../components/Quiz";
import { COOKIE_KEY, LOCAL_STORAGE_KEY, ROUTES, questions } from "../constants";
import Cookies from "js-cookie";
import { decodeValue, encodeValue } from "../libs/common";
import "swiper/css";
import { generateMagicKey } from "../utils/utils";


const QuizPage = () => {
  const [progress, setProgress] = useState<number>(() => {
    const savedProgress = localStorage.getItem("progress");
    return savedProgress ? parseInt(savedProgress, 10) : 20;
  });

  const navigate = useNavigate();
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass>();

  const initialSlide = useMemo(() => {
    const savedQuestion = localStorage.getItem("currentQuestion");
    return savedQuestion ? parseInt(savedQuestion, 10) : 0;
  }, []);

  const handleProgress = (currentQuestion: number) => {
    if (currentQuestion === 0) {
      setProgress(30);
      localStorage.setItem("progress", "30");
    }

    const from = 30;
    const difference = 70 / (questions.length - 2);
    const progressValue = from + (currentQuestion - 1) * difference;

    setProgress(progressValue);
    localStorage.setItem("progress", progressValue.toString());
  };

  const handleSelectAnswer = () => {
    const nextSlide = (swiperInstance?.activeIndex || 0) + 1;

    if (nextSlide < questions.length) {
      handleProgress(nextSlide);
      localStorage.setItem("currentQuestion", nextSlide.toString());
    } else {
      localStorage.removeItem("currentQuestion");
      localStorage.removeItem("progress");

      const isFinishQuiz = Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)
        ? decodeValue(Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)!)
        : "";

      const salesExpire = Cookies.get(COOKIE_KEY.SALES_EXPIRE)
        ? decodeValue(Cookies.get(COOKIE_KEY.SALES_EXPIRE)!)
        : "";

      if (salesExpire === "0" || !isFinishQuiz)
        Cookies.set(COOKIE_KEY.IS_FINISH_QUIZ, encodeValue("0"), {
          secure: true,
          sameSite: "Strict",
        });

        localStorage.setItem(LOCAL_STORAGE_KEY.IS_FILL_QUIZ, generateMagicKey(true))

      setTimeout(() => {
        navigate(ROUTES.OPTIN);
      }, 300);
    }

    swiperInstance?.slideNext();
  };

  return (
    <>
      <Box minHeight="100vh">
        <Box
          width="100%"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          height={{ base: "6px", lg: "12px" }}
        >
          <Box width="100%" height="100%">
            <CustomProgressBar
              value={progress}
              max={100}
              filledTrackColor="#D92D20"
              backgroundColor="#FEF3F2"
              height="100%"
            />
          </Box>
        </Box>

        <Box
          width="100%"
          pt={{
            base: [4, 5, 8].includes(
              Number(localStorage.getItem("currentQuestion")),
            )
              ? "10px"
              : "40px",
            lg: "60px",
            "2xl": [5, 8].includes(
              Number(localStorage.getItem("currentQuestion")),
            )
              ? "50px"
              : "160px",
          }}
          pb={
            parseInt(localStorage.getItem("currentQuestion") || "0") === 5
              ? "60px"
              : "0px"
          }
          margin="0 auto"
          minHeight={"100vh"}
        >
          <Swiper
            onSwiper={(swiper) => {
              setSwiperInstance(swiper);
            }}
            initialSlide={initialSlide}
            slidesPerView={1}
            speed={350}
            allowTouchMove={false}
            className={
              [5, 8].includes(Number(localStorage.getItem("currentQuestion")))
                ? "swiper-custom-fact"
                : "swiper-custom"
            }
          >
            {questions.map((question, index) => (
              <SwiperSlide key={question.type + index}>
                <Quiz
                  currentQuestion={index}
                  quiz={question}
                  handleSelectAnswer={handleSelectAnswer}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default QuizPage;
