import { Box, Text } from "@chakra-ui/react";

interface CalculationBoxProps {
  title: string;
  amount: string;
}

const CalculationBox: React.FC<CalculationBoxProps> = ({ title, amount }) => {
  return (
    <Box width={"100%"}  _hover={{ cursor: "pointer", transform: "scale(1.02)", transition: "all 0.2s ease-in-out" }}> 
      <Text fontSize="20px" fontWeight="900" mb={2} color="black">
        {title}
      </Text>

      <Box
        border="1px"
        borderColor="#D92D20"
        bg={"#FFFBFA"}
        borderRadius="30px"
        p={4}
        width={"100%"}
      >
        <Text fontSize={["32px", "28px", "24px"]} fontWeight="bold" color="#D92D20">
          {amount}
        </Text>

        <Text fontSize="18px" fontWeight="medium" color="#7E7E7E" mt={2}>
          Per month
        </Text>
      </Box>
    </Box>
  );
};

export default CalculationBox;
