import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { ROUTES } from "../constants";
import MembershipCourse from "../components/MembershipCourse";
import { figureBlueprintCourseContent } from "../components/MembershipCourse/content/figureBlueprintCourseContent";
import { launchpadCourseContent } from "../components/MembershipCourse/content/launchpadCourseContent";
import { endlessIdeaCourseContent } from "../components/MembershipCourse/content/endlessIdeaCourseContent";
import MemberShipNavbar from "../components/MemberShipNavbar";
import { CourseName } from "../components/MembershipCourse/types/MembershipCourses.type";
import { CloseIcon } from "../assets/icons";

const BluePrint = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    isPurchased7FigureLaunchPad = false,
    isPurchased7FigureBluePrint = false,
    isPurchasedEndlessVideoIdea = false,
  } = user || {};

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course = (queryParams.get("course") || "") as CourseName;
  const isOpenedMenu = !!queryParams.get("menu");

  const validCourses = ["7FigureBlueprint", "7FigureLaunchpad", "EndlessIdea"];

  const courseContent = {
    "7FigureBlueprint": figureBlueprintCourseContent,
    "7FigureLaunchpad": launchpadCourseContent,
    EndlessIdea: endlessIdeaCourseContent,
  };

  const isInvalidAccess =
    validCourses.indexOf(course) === -1 ||
    (course === "7FigureLaunchpad" && !isPurchased7FigureLaunchPad) ||
    (course === "7FigureBlueprint" && !isPurchased7FigureBluePrint) ||
    (course === "EndlessIdea" && !isPurchasedEndlessVideoIdea);

  const handleGoBackToPortal = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  const handleOnCloseMenu = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("menu");
    navigate(currentUrl.pathname + currentUrl.search, { replace: true });
  };

  return (
    // Back to 5-Day Fast Start
    <>
      {isInvalidAccess ? (
        <Navigate to={ROUTES.MEMBERSHIP} replace />
      ) : (
        <>
          {
            isOpenedMenu ? (
              <Flex
            as="nav"
            borderBottom="1px solid #EAECF0"
            height="60px"
            alignItems="center"
            justifyContent="space-between"
            px={{
              base: "20px",
              md: "30px",
            }}
          >
            <Text className="text-16 font-bold">Menu</Text>
            <Box onClick={handleOnCloseMenu}>
              <CloseIcon />
            </Box>
          </Flex>
            ) : (
              <MemberShipNavbar title="5-Day Fast Start" />
            )
          }
          

          {/* Courses */}
          <MembershipCourse
            courseName={`course=${course}`}
            courseContent={courseContent[course]}
            isOpenedMenu={isOpenedMenu}
          />
        </>
      )}
    </>
  );
};

export default BluePrint;
