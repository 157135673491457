import { cn } from "../../../utils/utils";
import InputErrorMessage from "../../InputErrorMessage";

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;

type EmailInputProps = {
  className?: string;
  placeholder?: string;
  isValidEmail?: boolean;
  onFocus?: () => void;
};

export const EmailInput = ({
  className,
  placeholder,
  isValidEmail,
  onFocus,
  ...field
}: EmailInputProps) => {
  return (
    <div className="relative w-full">
      {!isValidEmail && (
        <InputErrorMessage
          className="absolute right-0 -translate-y-full"
          message="Invalid mail address"
        />
      )}
      <input
        {...field}
        type="email"
        className={cn(
          "w-full rounded-lg border border-[#E7E7E7] px-[16px] py-2 placeholder:text-base",
          className,
          !isValidEmail && "focus:!border-red !border-[red]",
        )}
        placeholder={placeholder}
        onFocus={onFocus}
      />
    </div>
  );
};
