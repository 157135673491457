import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../../constants";
import { FirstYoutubePaycheckBtn } from "../FirstYoutubePaycheckBtn";

export const TestimonialSection = () => {
  return (
    <>
      <>
        <div className="mx-auto mt-5 max-w-[528px] px-6 md:px-0">
          <p className="text-18 md:text-26">
            Reinaldo got <span className="font-semibold">18k</span> views in
            total.
          </p>
        </div>
        <div className="mx-auto max-w-[608px]">
          <div className="relative">
            <div className="mt-10 hidden rounded-[18px] bg-[#F7F7F7] p-[15px] shadow-imgTestimonialShadow md:inline-block border b-[#E7E7E7]">
              <img src="/images/upsell1/reinaldo.webp" />
            </div>
            <div className="mt-10 md:hidden">
              <img src="/images/upsell1/reinaldo-mobile.webp" />
            </div>
            <div className="absolute left-[185px] top-[290px] hidden h-fit w-[473px] min-[1440px]:inline-block">
              <img src="/images/upsell1/reinaldo-arrow.webp " width={"100%"} />
            </div>
            <div className="absolute left-[110%] top-[240px] hidden h-[82px] w-[320px] min-[1440px]:inline-block">
              <img src="/images/upsell1/reinaldo-views.webp" width={"100%"} />
            </div>
          </div>
        </div>
      </>
      <>
        <div className="mx-auto max-w-[528px] px-6 md:mt-10 md:px-0">
          <p className="text-18 md:text-26">
            Sonia got <span className="font-semibold">9.2k</span> view.
          </p>
        </div>
        <div className="mx-auto max-w-[712px]">
          <div className="relative">
            <div className="mt-10 hidden rounded-[18px] border border-[#E7E7E7] p-[15px] shadow-imgTestimonialShadow md:inline-block">
              <img src="/images/upsell1/sonia.webp" />
            </div>
            <div className="mt-[30px] md:mt-10 md:hidden">
              <img src="/images/upsell1/sonia-mobile.webp" />
            </div>

            <div className="absolute left-[-44px] top-[64px] hidden h-[61px] w-[466px] min-[1440px]:inline-block">
              <img
                className="h-[61px] w-[466px]"
                src="/images/upsell1/sonia-arrow.webp"
              />
            </div>
            <div className="absolute left-[-231px] top-[79px] hidden min-[1440px]:inline-block">
              <img
                src="/images/upsell1/sonia-views.webp"
                className="h-[82px] w-[166px]"
              />
            </div>
          </div>
        </div>
      </>
      <>
        <div className="mx-auto max-w-[528px] px-6 md:mt-10 md:px-0">
          <p className="text-18 md:text-26">
            Will got almost <span className="font-semibold">10k</span> thousand
            views.
          </p>
        </div>

        <div className="mx-auto max-w-[712px]">
          <div className="relative">
            <div className="mt-10 hidden rounded-[18px] border border-[#E7E7E7] p-[15px] shadow-imgTestimonialShadow md:inline-block">
              <img src="/images/upsell1/will.webp" />
            </div>
            <div className="mt-[30px] md:mt-10 md:hidden">
              <img src="/images/upsell1/will-mobile.webp" />
            </div>
            <div className="absolute left-[459px] top-[88px] hidden h-[61px] w-[295px] min-[1440px]:inline-block">
              <img className="w-[295px]" src="/images/upsell1/will-arrow.webp" />
            </div>
            <div className="absolute right-[-62px] top-[79px] hidden translate-x-[100%] min-[1440px]:inline-block">
              <img
                src="/images/upsell1/will-views.webp"
                className="h-[82px] w-[247px]"
              />
            </div>
          </div>
        </div>
      </>
      <>
        <div className="mx-auto max-w-[528px] px-6 md:mt-10 md:px-0">
          <p className="text-18 md:text-26">
            And ryder got over <span className="font-semibold">50k</span> views
            on his very first video.
          </p>
        </div>

        <div className="mx-auto max-w-[608px]">
          <div className="relative">
            <div className="mt-10 hidden rounded-[18px] bg-[#F7F7F7] p-[15px] shadow-imgTestimonialShadow md:inline-block border b-[#E7E7E7]">
              <img src="/images/upsell1/ryder.webp" />
            </div>

            <div className="mt-[30px] md:mt-10 md:hidden">
              <img src="/images/upsell1/ryder-mobile.webp" />
            </div>

            <div className="absolute left-[143px] top-[134px] hidden h-[61px] w-[175px] translate-x-[-100%] min-[1440px]:inline-block">
              <img
                className="w-[175px]"
                src="/images/upsell1/ryder-arrow.webp"
              />
            </div>
            <div className="absolute left-[-50px] top-[64px] hidden translate-x-[-100%] min-[1440px]:inline-block">
              <img
                src="/images/upsell1/ryder-views.webp"
                className="h-[123px] w-[255px]"
              />
            </div>
          </div>
        </div>
      </>
      <div className="flex items-center justify-center px-6 md:mt-10 md:px-0">
        <HashLink smooth to={`${ROUTES.UPSELL1}#seven-figure-launchpad`}>
          <FirstYoutubePaycheckBtn  />
        </HashLink>
      </div>

      <div className="mx-auto max-w-[528px] px-6 text-18 md:px-0 md:text-26">
        <p className="mt-[30px] md:mt-10">
          And those who didn't do the work… They kept struggling to get it
          going.
        </p>
        <p className="mt-[15px] md:mt-10">
          From that, I learned a very valuable lesson:
        </p>
      </div>
    </>
  );
};
