import MemberShipNavbar from "../components/MemberShipNavbar";
import calculator from "../assets/images/calculator.webp";
import ProfitCalculatorComponent from "../components/ProfitCalculator";

const ProfitCalculator = () => {
  return (
    <div className="h-[100vh] overflow-y-auto">
      <MemberShipNavbar
        title="Channel Profit Calculator"
        iconUrl={calculator}
      />
      <ProfitCalculatorComponent />
    </div>
  );
};

export default ProfitCalculator;
