import { HashLink } from "react-router-hash-link";
import { ROUTES, URL_PARAMS } from "../../../constants";
import { cn } from "../../../utils/utils";
import { FirstYoutubePaycheckBtn } from "../../UpSell";
import WistiaPlayer from "../../WistiaPlayer";

export const IntroSection = () => {
  return (
    <div
      className={cn(
        "relative px-6 py-15 text-center",
      )}
    >
      <div className="text-28 mx-auto font-black capitalize text-black lg:max-w-3xl md:text-48">
        One More thing Before You Start...
      </div>

      <div className="mt-8 mx-auto md:px-4 md:py-8 max-w-2xl lg:max-w-3xl">
        <WistiaPlayer id="x9zx4wc2zj" />
      </div>

      <HashLink smooth to={`${ROUTES.DOWNSELL}?h=${URL_PARAMS.H_PARAM}#seven-figure-launchpad`}>
        <FirstYoutubePaycheckBtn className="mx-auto mt-[30px] w-full md:mt-[50px] md:w-auto" />
      </HashLink>

      {/* Background gradient */}
      <div className="green-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
    </div>
  );
};
