import { Text } from "@chakra-ui/react";
import NextIcon from "../NextIcon";

type CTAButtonProps = {
  onClick?: () => void;
};

const CTAButton = ({ onClick }: CTAButtonProps) => {
  return (
    <div className="mt-6 flex items-center justify-center md:px-0">
      <button
        className="bg-btnQualify shadow-btnSeeIfYou relative flex w-full items-center justify-center rounded-2xl py-[19.5px] text-white hover:bg-red-700 md:px-[13.5px] md:py-[18px]"
        onClick={onClick}
      >
        <Text
          className="text-16 md:text-26"
          fontWeight="600"
          lineHeight={{
            md: "27.83px",
          }}
          mr={{
            base: "2px",
            md: "8px",
          }}
        >
          See if you qualify for 1:1 help from Jake
        </Text>{" "}
        <NextIcon variant="white" />
        <Text className="absolute -top-3 right-6 rounded-[9px] bg-white px-4 py-[6px] text-base font-bold leading-[20.24px] text-black shadow-lastSpotsRemain">
          Last spots remaining
        </Text>
      </button>
    </div>
  );
};

export default CTAButton;
