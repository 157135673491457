import { Box, Flex, Image, Text } from "@chakra-ui/react";

import background from "../assets/images/interview/background.webp";
import WistiaPlayer from "../components/WistiaPlayer";

const InterViewPage = () => {
    return (
        <>
            <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
                <Box
                    width={{
                        base: "90%",
                        md: "max-content",
                    }}
                    margin="0 auto"
                >
                    <Text
                        className="mb-[40px]"
                        align={"center"}
                        fontWeight="900"
                        style={{
                            maxWidth: "631px",
                            fontSize: "clamp(32px, 5vw, 48px)",
                            marginBottom: "clamp(20px, 5vw, 40px)",
                            lineHeight: "clamp(35.84px, 5vw, 60.72px)",
                        }}
                    >
                        Please watch this important video below in full:
                    </Text>
                    <WistiaPlayer id={"wp3fjxktp7"} />
                </Box>
                <Image
                    src={background}
                    alt="Opt In"
                    position="absolute"
                    bottom="0"
                    left="0"
                    zIndex="-1"
                    w="100%"
                    h="100%"
                />
            </Flex>
        </>
    )
}

export default InterViewPage;