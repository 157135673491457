import { cn } from "../../../utils/utils";

export const PHONE_REGEX = /^\+?[1-9]\d{3,14}$/;
export const PHONE_NUMBER_REGEX = /^\d{7,15}$/;

type PhoneNumberInputProps = {
  className?: string;
  placeholder?: string;
  isValidPhoneNumber?: boolean;
  showMessage?: boolean;

  invalidMessage?: string;
};

export const PhoneNumberInput = ({
  className,
  placeholder,
  isValidPhoneNumber,
  showMessage = true,
  invalidMessage = "Please enter a valid phone number",
  ...field
}: PhoneNumberInputProps) => {
  return (
    <div className="relative w-full">
      {!isValidPhoneNumber && showMessage ? (
        <div className="absolute right-0 -translate-y-full font-normal text-red-600 xxxs:mb-2 xxxs:text-12 md:text-16">
          {invalidMessage}
        </div>
      ) : null}
      <input
        {...field}
        type="tel"
        id="input_phone" // used to trigger auto selected when user select country
        className={cn(
          "w-full rounded-lg border border-[#E7E7E7] px-[16px] py-2 placeholder:text-14",
          !isValidPhoneNumber && "focus:!border-red !border-[red]",
          className,
        )}
        placeholder={placeholder}
        aria-invalid={!isValidPhoneNumber}
      />
    </div>
  );
};
