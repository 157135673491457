import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useAuth } from "../providers/AuthProvider";
import { Flex, Spinner } from "@chakra-ui/react";
import { COOKIE_KEY, LOCAL_STORAGE_KEY, ROUTES } from "../constants";
import { decodeQueryParams } from "../utils/utils";

const VerifyPage = () => {
  const [searchParams] = useSearchParams();
  const { checkAuth } = useAuth();

  let token = searchParams.get("token");
  let isUnlockVideo = false;
  const magicLink = searchParams.get("q");
  let unlockVideo = searchParams.get("unlockvideo");
  let userId = searchParams.get("userId");
  let toPage = searchParams.get("to")
    ? "/" + searchParams.get("to")
    : ROUTES.MEMBERSHIP;
  const activeCampaign = searchParams.get("ac");

  const navigate = useNavigate();

  useEffect(() => {
    verifyAuth();

    /**
     * Automation link from AC
     * https://trello.com/c/s9K0LEPF/374-set-up-automation-that-after-the-fast-start-purchase
     */
    if (toPage === ROUTES.UPSELL1 && activeCampaign !== null) {
      // OTO1_LOGIN
      Cookies.set(COOKIE_KEY.OTO1_LOGIN, "true", {
        secure: true,
        sameSite: "Strict",
      });
    }
  }, []);

  const verifyAuth = async () => {
    if (magicLink) {
      const {
        token: magicToken,
        userId: magicUserId,
        unlockVideo: magicUnlockVideo,
        isUnlockVideo: isUnlockVideoByLink,
      } = decodeQueryParams(magicLink);
      token = magicToken;
      userId = magicUserId;
      unlockVideo = magicUnlockVideo;
      isUnlockVideo = isUnlockVideoByLink;
    }

    // If there is no token, redirect to login page
    if (!token) {
      navigate(ROUTES.LOGIN);
      return;
    }

    // Otherwise, set the token and unlockvideo in the cookie and redirect to the fast-start page
    Cookies.set(COOKIE_KEY.TOKEN, token, {
      secure: true,
      sameSite: "Strict",
      expires: 365 * 10, // expires in 10 years
    });

    if (userId) {
      Cookies.set(COOKIE_KEY.USER_ID, userId, {
        secure: true,
        sameSite: "Strict",
        expires: 365 * 10, // expires in 10 years
      });
    }

    searchParams.delete("token");
    searchParams.delete("unlockVideo");
    searchParams.delete("userId");
    searchParams.delete("to");

    await checkAuth();

    if (unlockVideo === "0") {
      localStorage.setItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY, unlockVideo);
      Cookies.set(COOKIE_KEY.UNLOCK_VIDEO, unlockVideo);
    }

    if (isUnlockVideo && unlockVideo) {
      localStorage.setItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY, unlockVideo);
      Cookies.set(COOKIE_KEY.UNLOCK_VIDEO, unlockVideo);
    }

    const regex = /\/\//g;

    if (toPage.match(regex)) {
      toPage = toPage.replace(regex, "/");
    }

    navigate(toPage);
  };

  return (
    <Flex
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="xl" />
    </Flex>
  );
};

export default VerifyPage;
