import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import { COOKIE_KEY } from "../../constants";
import { decodeValue, encodeValue } from "../../libs/common";
import { cn } from "../../utils/utils";

export const CheckoutCountdown = () => {
  const [endTime, setEndTime] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState({
    minutes: 0,
    seconds: 0,
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const updateTimeRemaining = useCallback((time: number) => {
    const min = Math.floor((time / 1000 / 60) % 60);
    const sec = Math.floor((time / 1000) % 60);
    setTimeRemaining({ minutes: min, seconds: sec });
  }, []);

  const calculateRemainingTime = useCallback(() => {
    const timeRemaining = endTime - Date.now();
    if (timeRemaining <= 0) {
      setTimeRemaining({ minutes: 0, seconds: 0 });
      Cookies.remove(COOKIE_KEY.CHECKOUT_TIMER);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      return;
    }

    updateTimeRemaining(timeRemaining);
  }, [endTime, updateTimeRemaining]);

  const initializeTimer = useCallback(() => {
    const currentTime = Date.now();
    // NOTE: Please reset this field after the test has finished :((
    const defaultDuration = 10 * 60 * 1000; // 10 minutes
    const initialEndTime = Cookies.get(COOKIE_KEY.CHECKOUT_TIMER)
      ? parseInt(decodeValue(Cookies.get(COOKIE_KEY.CHECKOUT_TIMER)!))
      : currentTime + defaultDuration;

    if (!Cookies.get(COOKIE_KEY.CHECKOUT_TIMER)) {
      Cookies.set(
        COOKIE_KEY.CHECKOUT_TIMER,
        encodeValue(initialEndTime.toString()),
        {
          expires: 1 / 144, // 10 minutes
          secure: true,
          sameSite: "Strict",
        },
      );
    }

    setEndTime(initialEndTime);
    updateTimeRemaining(initialEndTime - currentTime);
  }, [updateTimeRemaining]);

  const addMoreTime = (additionalMinutes: number) => {
    const additionalMilliseconds = additionalMinutes * 60 * 1000;
    const currentEndTime = Cookies.get(COOKIE_KEY.CHECKOUT_TIMER)
      ? parseInt(decodeValue(Cookies.get(COOKIE_KEY.CHECKOUT_TIMER)!), 10)
      : Date.now();

    const newEndTime = currentEndTime + additionalMilliseconds;

    setEndTime(newEndTime);
    Cookies.set(COOKIE_KEY.CHECKOUT_TIMER, encodeValue(newEndTime.toString()), {
      expires: !Cookies.get(COOKIE_KEY.CHECKOUT_TIMER) ? 3 / 1440 : 1 / 144,
      secure: true,
      sameSite: "Strict",
    });

    updateTimeRemaining(newEndTime - Date.now());
  };
  useEffect(() => {
    initializeTimer();
    // const interval = setInterval(calculateRemainingTime, 1000);
    // return () => clearInterval(interval);
    intervalRef.current = setInterval(calculateRemainingTime, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Clear interval khi component unmount
      }
    };
  }, [calculateRemainingTime, initializeTimer]);

  return (
    <div className="mt-[33px]">
      <div
        className={cn(
          "container-1200 flex items-center justify-center gap-3",
          timeRemaining.minutes < 3 ? "flex-col md:flex-row" : "",
        )}
      >
        <div className="flex w-fit items-center justify-center gap-[10px] rounded-[17px] bg-black px-3 py-1 font-bold text-white">
          <div className="text-14 font-medium leading-[12px]">
            We’re holding your spot for
          </div>
          <div className="flex min-w-[74px] justify-center rounded-[20px] bg-[#D92D20] px-[15px] py-0 text-center">
            {`${timeRemaining.minutes < 10 ? `0${timeRemaining.minutes}` : timeRemaining.minutes}:${timeRemaining.seconds < 10 ? `0${timeRemaining.seconds}` : timeRemaining.seconds}`}
          </div>
        </div>
        {timeRemaining.minutes < 3 && (
          <div
            className="w-fit cursor-pointer rounded-[17px] bg-[#FEE4E2] px-[14px] py-2 text-14 font-bold text-[#D92D20]"
            onClick={() => addMoreTime(3)}
          >
            Need more time?
          </div>
        )}
      </div>
    </div>
  );
};
