// components/NavBar.tsx

import { useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import ArrowLeftIcon from "../ArrowLeftIcon";
import AvatarIcon from "../AvatarIcon";
import LogoutIcon from "../LogoutIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

interface NavBarProps {
  title: string;
  iconUrl?: string; // Optional prop for the image URL
}

const MemberShipNavbar: React.FC<NavBarProps> = ({ title, iconUrl }) => {
  const [isHoveredAvatar, setIsHoveredAvatar] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleGoBack = () => {
    navigate(ROUTES.MEMBERSHIP);
  };

  return (
    <Flex
      as="nav"
      borderBottom="1px solid #EAECF0"
      height="60px"
      alignItems="center"
      justifyContent="space-between"
      px={{
        base: "20px",
        md: "30px",
      }}
    >
      <Flex alignItems="center" cursor="pointer" onClick={handleGoBack}>
        <ArrowLeftIcon />
        <Flex alignItems="center">
          <Heading
            fontWeight="700"
            fontSize="16px"
            lineHeight="20.24px"
            ml="2px"
            display="flex"
            alignItems="center"
          >
            {iconUrl && (
              <Image
                src={iconUrl}
                alt="Icon"
                width="24px"
                height="24px"
                mr="8px" // Add spacing between the title and image
              />
            )}
            {title}
            {/* Render the image/icon only if the URL is provided */}
          </Heading>
        </Flex>
      </Flex>
      {/* Avatar Section */}
      {user && (
        <Flex
          height="60px"
          alignItems="center"
          onMouseOver={() => setIsHoveredAvatar(true)}
          onMouseOut={() => setIsHoveredAvatar(false)}
        >
          <Flex
            className="-mr-6 md:mr-0"
            position="relative"
            height="40px"
            alignItems="center"
            p="8px 12px"
            borderRadius="12px"
            cursor="pointer"
            background={isHoveredAvatar ? "#f2f4f7" : "transparent"}
          >
            <AvatarIcon />
            <Box
              className="hidden md:block"
              ml="8px"
              fontSize="16px"
              fontWeight="450"
              lineHeight="20.24px"
              color="#9b9b9b"
            >
              Welcome,{" "}
              <Box as="span" color="#000" fontWeight="500">
                {user?.firstName}
              </Box>
            </Box>

            {isHoveredAvatar && (
              <Flex
                position="absolute"
                top="calc(100% + 6px)"
                right="0"
                alignItems="center"
                p="8px 29px 8px 12px"
                background="#fff"
                border="1px solid #eaecf0"
                borderRadius="7px"
                onClick={logout}
              >
                <LogoutIcon />{" "}
                <Text
                  ml="5px"
                  fontSize="14px"
                  lineHeight="17.31px"
                  fontWeight="500"
                >
                  Logout
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default MemberShipNavbar;
