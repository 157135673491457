import { GraySection } from "../ui";
import Tooltip from "../ui/Tooltip";

export const GuarantyDays = () => {
  return (
    <GraySection className="mb-[15px] xxxs:mx-3.5 xxxs:mt-[20px] md:mx-6 md:mb-[25px] lg:m-[25px]">
      <div className="flex items-center justify-center">
        <img src="/checkout/moneyback.svg" alt="365-day-money-back" />
      </div>

      <Tooltip
        label={
          <div className="text-14 font-semibold">
            If you're not happy for any reason whatsoever let us know. We'll
            issue you a full refund - No Questions Asked!
          </div>
        }
        tooltipLabelClassName="!left-[0px] !top-[-15px] md:!left-[30px] md:!top-[-12px] !bg-[#039855] text-white arrow-green  after:left-[85px] before:left-[85px] md:after:left-[58px] md:before:left-[58px] md:!w-[396px] !py-[15px]"
      >
        <div className="mx-auto mt-5 max-w-full px-[5px] text-16 font-bold-450 leading-5 text-[#424242]">
          Here's the deal: I'm so confident that you'll see the value in the
          Faceless Income Fast-Start that I'm giving you my 365-day,
          no-questions-asked guarantee. Yeah, you read that right - If you feel
          like you haven't gotten your money's worth, just shoot me an email any
          day of the year... Heck, even if it's day 364. You'll get a full
          refund, no questions asked.{" "}
          <span className="font-bold text-black">
            You could literally watch everything, implement it, make money, keep
            the money, and still get your money back if you're not satisfied.
          </span>
        </div>
      </Tooltip>
    </GraySection>
  );
};
