import { Box } from "@chakra-ui/react";
import WinningStudents from "../homepage/WinningStudents";
import FastStartHeadLine from "./FastStartHeadline";

const SuccessStory = () => {
  return (
    <Box pb="100px">
      <Box maxWidth={"1100px"} margin="0 auto">
        <FastStartHeadLine text="Are you our next success story?" />
      </Box>
      <WinningStudents className="overflow-hidden" />
    </Box>
  );
};

export default SuccessStory;
