import { Box, Image } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { avatars } from "../../constants";
import { useIntersectionElement } from "../../hooks/useIntersectionElement";
import { useIsMobile } from "../../hooks/useIsMobile";
import { cn } from "../../utils/utils";
import SalesButton from "../SalesButton";

import GetInstantParticipated from "../SalesPageComponents/parts/GetInstantParticipated";
import GetInstantServices from "../SalesPageComponents/parts/GetInstantServices";

export interface GetInstantAccessProps
  extends ComponentPropsWithoutRef<"section"> {
  title: string;
  isFillQuiz?: boolean;
  videoPlayer: ReactNode;
  isDelayedContent: boolean;
  showHiddenContent: boolean;
}

const kDelayedContentStyle = (isDelayedContent: boolean, isMobile: boolean) =>
  cn(
    isDelayedContent
      ? `${isMobile ? "invisible" : "hidden"}  opacity-0`
      : "block opacity-100",
    "transition-all ease-in-out duration-1000",
  );

export const getInstantAccessIntersectingEvent = "getInstantAccessIntersecting";

const GetInstantAccess = ({
  title,
  isFillQuiz,
  showHiddenContent,
  videoPlayer,
  isDelayedContent,
  className,
  ...props
}: GetInstantAccessProps) => {
  const [ref, entry] = useIntersectionElement();
  const isMobile = useIsMobile();

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("getInstantAccessIntersecting", {
        detail: entry?.isIntersecting,
      }),
    );
  }, [entry?.isIntersecting]);

  return (
    <section
      {...props}
      className={cn(
        "relative flex flex-col items-center justify-center pb-[49px] pt-8 text-center md:pb-[60px]",
        className,
      )}
    >
      <h1
        className={`${isFillQuiz ? "mb-6 text-32 capitalize md:text-44" : "mb-[9px] xs:w-[380px] md:mb-[18px] md:text-[36px] md:leading-10 lg:text-44 xl:text-48"} md:w-5xl relative mx-auto px-8 text-[28px] font-[900] leading-[37px] text-black sm:w-[380px] md:w-full md:px-16 lg:max-w-6xl`}
      >
        {title}
        {/* {isFillQuiz && (
          <Image
            src={SalesArrow}
            alt="Sale arrow action"
            loading="lazy"
            className={cn(
              "absolute -bottom-[45px] right-[30px] md:-bottom-[60px] lg:right-[50px]",
              "h-[45px] w-[25px] md:h-[80px] md:w-[45px]",
              "origin-top -rotate-45 md:rotate-0",
            )}
          />
        )} */}
      </h1>
      {!isFillQuiz && (
        <p className="font- relative mx-auto mb-[25px] w-[350px] px-8 text-center text-[17px] font-[500] xxxs:w-[300px] md:mb-8.75 md:w-full md:max-w-5xl md:px-16 lg:mb-11 lg:max-w-6xl lg:text-24 xl:mb-[67px]">
          All without ever showing your face or creating the videos yourself
        </p>
      )}
      <Box position="relative" w="full" mb="10">
        {videoPlayer}
      </Box>

      <div className={kDelayedContentStyle(isDelayedContent, isMobile)}>
        {showHiddenContent && (
          <SalesButton ref={ref} className="mb-[13px] md:mb-[21px] md:mt-9" />
        )}

        {showHiddenContent && (
          <>
            <GetInstantParticipated
              items={avatars}
              className="mb-[28px] justify-center md:mb-10"
            />
          </>
        )}
        {showHiddenContent && <GetInstantServices />}
      </div>
      {/* Background gradient */}
      <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-[134vh] w-full lg:h-[119vh] xl:h-[125vh] 2xl:h-[130vh]"></div>
    </section>
  );
};

export default GetInstantAccess;
