import { ComponentPropsWithoutRef } from "react";
import SalesGroup1 from "../../assets/images/sales_group1.webp";
import { cn } from "../../utils/utils";
import GroupSpeak from "./assets/Group.webp";
// import Speak1 from "./assets/Speak1.png";
// import Speak2 from "./assets/Speak2.png";
// import Speak3 from "./assets/Speak3.png";
// import Speak4 from "./assets/Speak4.png";
import { Image } from "@chakra-ui/react";
import { useSalesExpired } from "../CountDown/useSalesExpired";
import SpeakStep from "./assets/SpeakStep.webp";
import SpeakStepMobile from "./assets/SpeakStepMobile.webp";
import { PreloadImage } from "../ui/Image/PreLoadImage";

export interface ImageCardProps {
  url: string;
  position: number;
}

// const ImageCard = ({ url, position }: ImageCardProps) => {
//   return (
//     <>
//       {position === 1 || position === 3 ? (
//         <div className="mb-3 mr-0 max-w-[336px] rounded-[16px] border-[5px] border-[#4E4E4E] shadow-2xl md:-mb-10 md:-mr-[32%] md:mt-0 md:border-[7px] lg:max-w-[663px] lg:rounded-[20px] 2xl:-mb-16 2xl:border-[10px]">
//           <img
//             className="rounded-[10px] object-fill lg:rounded-[8px]"
//             src={url}
//             loading="lazy"
//           />
//         </div>
//       ) : (
//         <div className="mb-3 ml-0 max-w-[336px] rounded-[16px] border-[5px] border-[#4E4E4E] shadow-2xl md:-mb-10 md:-ml-[32%] md:mt-0 md:border-[7px] lg:max-w-[663px] lg:rounded-[20px] 2xl:-mb-16 2xl:border-[10px]">
//           <img
//             className="rounded-[10px] object-fill lg:rounded-[8px]"
//             src={url}
//             loading="lazy"
//           />
//         </div>
//       )}
//     </>
//   );
// };

export interface ResultSpeakProps extends ComponentPropsWithoutRef<"div"> { }

const ResultSpeak = ({ className, ...props }: ResultSpeakProps) => {
  const isSalesExpired = useSalesExpired();
  return (
    <div
      {...props}
      id="resultspeak"
      className={cn(
        "flex flex-col items-center justify-center",
        isSalesExpired
          ? "scroll-mt-28 md:scroll-mt-40"
          : "scroll-mt-[168px] md:scroll-mt-[216px]",
        className,
      )}
    >
      <Image
        src={SpeakStep}
        alt="Steps"
        className="mx-auto hidden -translate-y-4 transform object-cover md:block"
      />

      <Image
        src={SpeakStepMobile}
        alt="Steps"
        className="-ml-0 w-[calc(100%+48px)] max-w-none -translate-y-4 transform object-cover md:ml-0 md:hidden md:w-full"
      />

      <div
        className={cn(
          "translate-x-2 transform sm:translate-x-0",
          "-ml-6 flex max-h-[415vw] w-[calc(100%+48px)] max-w-[calc(100%+48px)]",
          "flex-col justify-center lg:hidden",
        )}
      >
        <PreloadImage src={GroupSpeak} alt="" className="object-cover" />
      </div>
      <div className="hidden max-w-[344px] lg:block lg:max-w-[1065px]">
        <PreloadImage src={SalesGroup1} className="mx-auto" />
      </div>
    </div>
  );
};

export default ResultSpeak;
