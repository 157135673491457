import {
  AbsoluteCenter,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import WistiaPlayer from "../../WistiaPlayer";
import "./style.css";

const ModalVideoPlayer = ({
  onClose,
  wistiaId,
}: {
  onClose?: () => void;
  wistiaId: string | null;
}) => {
  const {
    isOpen,
    onOpen,
    onClose: onCloseModal,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const handleClose = () => {
    onCloseModal();
    onClose?.();
  }

  useEffect(() => {
    !!wistiaId && onOpen();
  }, [wistiaId]);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose?.();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size={"full"}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="4px" />
      <ModalContent background={"transparent"}>
        <ModalCloseButton zIndex={100} color={"white"} background={"black"} />
        <ModalBody onClick={handleOutsideClick}>
          <AbsoluteCenter className="modal" >
            <WistiaPlayer id={wistiaId!} setCloseModal={handleClose} />
          </AbsoluteCenter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ModalVideoPlayer;
