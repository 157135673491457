/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AMEXIcon,
  BankingIcon,
  JCBIcon,
  MasterCardIcon,
  VisaIcon,
} from "../../assets/icons";
import ApplePayButton from "../ApplePayButton";
import GooglePayButton from "../GooglePayButton";
import { GraySection, Input } from "../ui";
import { CountryRegionSelect } from "../ui/Select/CountryRegionSelect";
import { useCheckout } from "../../providers/CheckoutProvider";
import { Controller, FieldErrors } from "react-hook-form";
import InputErrorMessage from "../InputErrorMessage";
import ThreeDotsLoading from "../ThreeDotsLoading";
import { ChangeEvent, useRef } from "react";
import {
  checkIOS,
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "../../utils/utils";
import { CheckoutInputs, CheckoutPayload } from "../../types/type";
import { CheckoutModalInputs } from "../CheckoutModal";
import { useFallbackGooglePayStore } from "../../libs/store";

type BillingInformationProps = {
  amount: number;
  showSecureCheckout?: boolean;
  control: any;
  errors: FieldErrors<CheckoutInputs | CheckoutModalInputs>;
  isProcessing: ReturnType<typeof useCheckout>["isProcessing"];
  handleCheckout: (payload: CheckoutPayload) => Promise<void>;
  setPaymentError?: React.Dispatch<React.SetStateAction<string>>;
};

export const BillingInformation = ({
  amount,
  showSecureCheckout = true,
  handleCheckout,
  control,
  errors,
  isProcessing,
  setPaymentError = () => {},
}: BillingInformationProps) => {
  const isIOS = checkIOS();

  const ccnumberRef = useRef<HTMLInputElement>(null);
  const ccexpRef = useRef<HTMLInputElement>(null);
  const cvcRef = useRef<HTMLInputElement>(null);

  const fallbackGooglePay = useFallbackGooglePayStore(
    (state) => state.fallbackGooglePay,
  );

  const creditFieldError = errors.ccnumber || errors.ccexp || errors.cvv;

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...event: any[]) => void,
    name: string,
  ) => {
    let value = event.target.value;

    if (name === "ccnumber") {
      value = formatCreditCardNumber(value);

      if (value.length > 19) {
        ccexpRef.current?.focus();
        return;
      }
    } else if (name === "ccexp") {
      if (value.length === 0) {
        ccnumberRef.current?.focus();
      }

      value = formatExpirationDate(
        value,
        () => {
          event.target.classList.remove("text-red-500");

          if (value.length === 7) {
            cvcRef.current?.focus();
          }
        },
        () => {
          event.target.classList.add("text-red-500");
        },
      );
    } else if (name === "cvc" || name === "cvv") {
      value = formatCVC(value);

      if (value.length === 0) {
        ccexpRef.current?.focus();
      }
    }

    // TODO: Focus input, jump from one input to another

    onChange(value);
  };

  return (
    <GraySection
      className="mt-[25px]"
      label="Billing Information"
      icon={<BankingIcon />}
    >
      <div className="mt-[15px] md:mt-4">
        {isIOS && !fallbackGooglePay ? (
          <ApplePayButton
            amount={amount}
            handleCheckout={handleCheckout}
            setPaymentError={setPaymentError}
          />
        ) : (
          <GooglePayButton amount={amount} handleCheckout={handleCheckout} />
        )}
      </div>

      <div className="mt-[25px] flex items-center justify-center gap-[13px]">
        <div className="h-[1px] w-full bg-[#E7E7E7]"></div>
        <span className="whitespace-nowrap text-nowrap text-14 font-bold-450 text-[#424242] md:text-16">
          Or pay with card
        </span>
        <div className="h-[1px] w-full bg-[#E7E7E7]"></div>
      </div>
      <div className="mt-[25px]">
        <p className="text-15 font-medium">Card information</p>
        <div className="mt-[6px]">
          <div className="relative">
            <Controller
              name="ccnumber"
              disabled={isProcessing}
              control={control}
              rules={{ required: "Card number is required" }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={ccnumberRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e, field.onChange, "ccnumber");
                  }}
                  maxLength={20}
                  className={`w-full rounded-b-none pr-[200px]`}
                  placeholder="1234 1234 1234 1234"
                />
              )}
            />

            <div className="absolute right-0 top-0 flex gap-[10px] py-2 pr-2">
              <div className="h-[26px] w-[40px] rounded-lg border border-[#D6DCE5] bg-white px-[6px] py-2">
                <VisaIcon />
              </div>
              <div className="flex h-[26px] w-[40px] items-center justify-center rounded-lg border border-[#D6DCE5] bg-white px-[6px] py-[6px] md:py-2">
                <MasterCardIcon />
              </div>
              <div className="flex h-[26px] w-[40px] items-center justify-end rounded-lg border border-[#D6DCE5] bg-white px-[6px] py-[6px] pr-0 md:py-2">
                <AMEXIcon />
              </div>
              <div className="flex h-[26px] w-[40px] items-center justify-center rounded-lg border border-[#D6DCE5] bg-white px-[6px] py-[6px] md:py-2">
                <JCBIcon />
              </div>
            </div>
          </div>
          <div className="flex">
            <Controller
              disabled={isProcessing}
              name="ccexp"
              control={control}
              rules={{ required: "Expiration date is required" }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={ccexpRef}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e, field.onChange, "ccexp");
                  }}
                  pattern="\d\d/\d\d"
                  type="text"
                  className={`w-full rounded-r-none rounded-t-none`}
                  placeholder="MM / YY"
                />
              )}
            />

            <Controller
              name="cvv"
              disabled={isProcessing}
              control={control}
              rules={{
                minLength: 3,
                maxLength: 4,
                required: "CVC is required",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={cvcRef}
                  pattern="\d{3,4}"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e, field.onChange, "cvc");
                  }}
                  type="number"
                  className={`w-full rounded-l-none rounded-t-none`}
                  placeholder="CVC"
                />
              )}
            />
          </div>
          {creditFieldError && (
            <InputErrorMessage message={creditFieldError.message as string} />
          )}
        </div>

        {isProcessing && (
          <div className="mt-4 flex items-center justify-center">
            <ThreeDotsLoading />
          </div>
        )}

        <div className="mt-[25px]">
          <p className="text-15 font-medium">Country or region</p>
          <div className="mt-[6px]">
            <CountryRegionSelect
              disabled={isProcessing}
              control={control}
              errors={errors}
            />
          </div>
        </div>

        {showSecureCheckout && (
          <>
            <div className="my-[25px] h-[1px] bg-[#E7E7E7]"></div>
            <div className="flex items-center justify-center gap-[11px]">
              <img
                className="max-w-[325px] md:max-w-[411px]"
                src="/images/checkout/secure-checkout.webp"
                alt="secure-checkout"
                draggable={false}
              />
            </div>
          </>
        )}
      </div>
    </GraySection>
  );
};
