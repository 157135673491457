import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import product1 from "../assets/images/product-1.webp";
import product2 from "../assets/images/product-2.webp";

import day1 from "../assets/images/membership/Day1.webp";
import day2 from "../assets/images/membership/Day2.webp";
import day3 from "../assets/images/membership/Day3.webp";
import day4 from "../assets/images/membership/Day4.webp";
import day5 from "../assets/images/membership/Day5.webp";
import day6 from "../assets/images/membership/Day6.webp";

import calculator from "../assets/images/calculator.webp";
import lightbulb from "../assets/images/lightbulb.webp";
import ordering from "../assets/images/ordering.webp";
import warning from "../assets/images/warning.webp";

import adTemplate from "../assets/images/ad-template.webp";
import contractTemplate from "../assets/images/contract-template.webp";
import email from "../assets/images/email.webp";
import musicLibrary from "../assets/images/music-library.webp";

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import BookCallPopUp from "../components/BookCallPopUp";
import FastStartMainVideo from "../components/FastStartPortal/FastStartMainVideo";
import FastStartProducts from "../components/FastStartPortal/FastStartProducts";
import FastStartVideos from "../components/FastStartPortal/FastStartVideos";
import SuccessStory from "../components/FastStartPortal/SuccessStory";
import NavbarProfile from "../components/NavbarProfile";
import {
  ACTIVE_VIDEO_INDEX,
  LOCAL_STORAGE_KEY,
  ROUTES,
  URL_PARAMS,
} from "../constants";
import { useIsMobile } from "../hooks/useIsMobile";
import { useAuth } from "../providers/AuthProvider";
import PurchaseEndlessVideoIdeaModal from "../components/PurchaseEndlessVideoIdeaModal";
import CheckoutModal from "../components/CheckoutModal";
import useMagicPrice from "../components/CountDown/useMagicPrice";
import { getLocalStorage, setLocalStorage } from "../utils/utils";

export type FastStartProductType = {
  imageUrl: string;
  title: string;
  description: string;
  buttonClassName?: string;
  badge: string;
  button?: JSX.Element;
  secondButton?: JSX.Element;
};

export type VideoType = {
  imageUrl: string;
  title: string;
  videoId: string;
};

type ProductButtonProps = {
  text: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};
const ProductButton = ({
  text,
  disabled,
  className,
  onClick,
}: ProductButtonProps) => {
  return (
    <button
      className={`'md-min-h-[49px] :disabled:bg-gray-300 min-h-[44px] whitespace-normal rounded-[14px] bg-[#f2f4f7] px-6 py-3 !text-16 font-medium !leading-[19.78px] transition-all duration-300 ease-linear hover:bg-gray-300 lg:min-w-min lg:!text-20 lg:!leading-[24.73px] xl:whitespace-nowrap ${className}`}
      onClick={() => {
        if (!disabled) onClick?.();
      }}
    >
      {text}
    </button>
  );
};

const MemberShipPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const { endless } = useMagicPrice();

  const {
    isPurchased7FigureLaunchPad = false,
    isPurchased7FigureBluePrint = false,
    isPurchasedEndlessVideoIdea = false,
  } = user || {};

  let unlockVideo = user?.unlockVideo ?? 0;
  if (unlockVideo === -1) unlockVideo = 6;

  /// initialize to the latest unlocked video
  /// unlockVideo is 1 based -> handle accordingly.
  /// for users that completed fast start -> default to the last video.
  const { activeVideoIndex, handleSetActiveVideoIndex } = useActiveVideoIndex();
  const checkoutModal = useDisclosure();

  const handlePaymentFailed = () => {
    checkoutModal.onOpen();
  };

  const getPurchasedStatus = (status: boolean) => {
    if (status) {
      return "Purchased";
    }
    return "Not Purchased";
  };

  const getMenuParam = () => {
    if (isMobile) {
      return "&menu=1";
    }

    return "";
  };

  const purchaseProducts: FastStartProductType[] = [
    {
      imageUrl: product1,
      title: "Endless Video Idea System",
      description:
        "Jake's endless video idea system is the ultimate shortcut to finding new, unique and proven video ideas for any faceless channel",
      buttonClassName: "!px-1 min-[811px]:!py-3 min-[811px]:!px-6",
      badge: getPurchasedStatus(isPurchasedEndlessVideoIdea),
      button: (
        <PurchaseEndlessVideoIdeaModal
          handlePaymentFailed={handlePaymentFailed}
        >
          <ProductButton
            text="Find Proven Video Ideas In Minutes"
            disabled={!isPurchasedEndlessVideoIdea}
            onClick={() =>
              navigate(
                `${ROUTES.BLUEPRINT}?course=EndlessIdea${getMenuParam()}`,
              )
            }
          />
        </PurchaseEndlessVideoIdeaModal>
      ),
    },
    {
      imageUrl: product2,
      title: "7-Figure Launchpad",
      description:
        "Skip the learning and get straight to earning: With the 7-Figure Launchpad, you get access to proven tools, scripts, templates, systems, and more, all refined over my 5 years on YouTube. It allows you to create viral videos on command, attract sponsors & mulitply your income. Over 91% of our students got their 7-Figure Launchpad because to skip months of trial end error.",
      badge:
        getPurchasedStatus(isPurchased7FigureLaunchPad) ||
        getPurchasedStatus(isPurchased7FigureBluePrint),
      button: (
        <ProductButton
          text={
            !isPurchased7FigureBluePrint && !isPurchased7FigureLaunchPad
              ? "Get Views On Demand, Attract Sponsors, Brand Deals And Multiply Your Income"
              : "Get Views On Demand"
          }
          onClick={() =>
            !isPurchased7FigureLaunchPad
              ? navigate(`${ROUTES.UPSELL1}?h=${URL_PARAMS.H_PARAM}`)
              : navigate(
                  `${ROUTES.BLUEPRINT}?course=7FigureLaunchpad${getMenuParam()}`,
                )
          }
        />
      ),
      secondButton: isPurchased7FigureBluePrint ? (
        <ProductButton
          text="Attract Sponsors, Brand Deals And Multiply Your Income"
          onClick={() => {
            navigate(
              `${ROUTES.BLUEPRINT}?course=7FigureBlueprint${getMenuParam()}`,
            );
          }}
        />
      ) : undefined,
    },
  ];

  const freeBonuses: FastStartProductType[] = [
    {
      imageUrl: lightbulb,
      title: "100+ CHANNEL IDEAS",
      description:
        "Jake’s personal list of the untapped viral channel ideas. If Jake was to start over today, these would be the profitable channel ideas he would start because it's guaranteed that they will go viral.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Download 100+   CHANNEL IDEAS"
          onClick={() => {
            window.open(
              "https://drive.google.com/file/d/1UuyXKeQj97xVmGWzfz2mFIcr4c1DMjA7",
            );
          }}
        />
      ),
    },
    {
      imageUrl: calculator,
      title: "CHANNEL PROFIT CALCULATOR",
      description:
        "This plug-and-play channel profit calculator helps you predict how your channel will perform based on real data from Jake’s own channels, all students' channels, and recent YouTube statistics.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Calcuate Channel Profit"
          onClick={() => {
            navigate(
              `${ROUTES.PROFIT_CALCULATOR}`,
            );
          }}
        />
      ),
    },
    {
      imageUrl: ordering,
      title: "CHANNEL LAUNCH CHECKLIST",
      description: `Make sure you don’t miss any crucial elements from this 10+ step checklist (applies to every channel, regardless of niche), and don’t leave any "free" money on the table.`,
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Download Checklist"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1Mt0nQyelHxtE2gTckyKJaznBlI0cIzPYV0QxJjrq1KM",
            );
          }}
        />
      ),
    },
    {
      imageUrl: warning,
      title: "YOUTUBE FLAG WORDS",
      description:
        "Few people are aware of them, but they do exist; avoid these niches and mentioning any of these words, as they could cause your channel to be demonetized.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="See The Flag Words"
          onClick={() => {
            window.open(
              "https://www.loom.com/share/ddf8fabaaee94864a820024d83f58604?sid=363667bd-1824-4f52-a978-af55f0609b33",
            );
          }}
        />
      ),
    },
    {
      imageUrl: email,
      title: "SPONSOR EMAIL OUTREACH TEMPLATE",
      description:
        "Land as many sponsor deals as you need with this customizable outreach template. Simply fill in the blanks with details like the company name and your channel & shoot it over!",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Copy the Sponsor Email Outreach Template"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1am-n0nc0p1nXOnyYQka3nXz_inHrinT1v9aS91A9x9o",
            );
          }}
        />
      ),
    },
    {
      imageUrl: contractTemplate,
      title: "SPONSOR CONTRACT TEMPLATE",
      description:
        "Once you secure a sponsor, seal the deal with the no-BS contract I use with my own sponsors! It ensures you get paid and clearly outlines everything to keep both parties protected.",
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Copy the Sponsor Contract Template"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1_l-6RETCw3VyoFQasMg1J11xNJmwbnXO-l73RreQq4w",
            );
          }}
        />
      ),
    },
    {
      imageUrl: adTemplate,
      title: "MILLION DOLLAR AD TEMPLATE",
      description: `Once you've secured your sponsorship deal and the contract is signed, promoting their business becomes a breeze with this "fill-in-the-blanks" Million Dollar Ad Template. Just follow the simple 6-step framework, input the necessary details, and your video is ready to go!`,
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Copy the Million Dollar Ad Template"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1puXnHw_Rr0iaY9lClTNOC6zn-zSpzn2a0RwKZ2Osfw8",
            );
          }}
        />
      ),
    },
    {
      imageUrl: musicLibrary,
      title: "MUSIC LIBRARY I USE TO LICENSE MUSIC FOR MY VIDEOS",
      description: `I use Epidemic Sound for all my channel's music. It has lots of great songs that don't cost much and are okay to use in any video.`,
      badge: "Free Bonus",
      button: (
        <ProductButton
          text="Go to Epidemic Sound"
          onClick={() => {
            window.open(
              "https://www.epidemicsound.com/campaign/ambassador-sign-up/?_us=Affiliate&_usx=8gsv5a&utm_source=ambassador&utm_medium=affiliate&utm_campaign=8gsv5a",
            );
          }}
        />
      ),
    },
  ];

  const videos: VideoType[] = useMemo(() => {
    return [
      {
        imageUrl: "",
        title: "Welcome to the Fast Start 🙌",
        videoId: "cirtflugsy",
      },
      {
        imageUrl: day1,
        title: "How I Make Over $1,000,000 Per Year With YouTube",
        videoId: "0uie5of0o8",
      },
      {
        imageUrl: day2,
        title: "Your Million-Dollar YouTube Channel",
        videoId: "nvw66ic4zr",
      },
      {
        imageUrl: day3,
        title: "Your Profit Factory",
        videoId: "8p6sh2n6bm",
      },
      {
        imageUrl: day4,
        title: "Getting Your First Paycheck: $0 to $10,000",
        videoId: "zk2rmd2o0i",
      },
      {
        imageUrl: day5,
        title: "From $10,000 to $100,000",
        videoId: "y6a9pjkzz2",
      },
      {
        imageUrl: day6,
        title: "Scaling Beyond 6-Figures",
        videoId: "rbxuhp7lql",
      },
    ];
  }, []);

  const { isOpen: isOpenBookCallPopUp, handleClose: handleCloseBookCallPopUp } =
    useBookCallPopup();

  const isDisplayQualifyBtn = useMemo(() => {
    // Ensure watchedVideos is available and is an array
    const videos = Object.values(user?.watchedVideos || {});
    if (videos.length === 0) return false;

    const currentTime = Date.now();

    // Check for Case 1
    const videoIndex1 = videos.find((video) => video.videoIndex === 1);
    if (videoIndex1) {
      const { percent: percentWatched, progress: seekTime } = videoIndex1;
      if (percentWatched > 50 && seekTime > 0.95) {
        return true; // Case 1
      }

      let firstConditionTime = getLocalStorage("firstConditionTime");

      if (!firstConditionTime) {
        firstConditionTime = new Date().getTime();
        setLocalStorage("firstConditionTime", firstConditionTime);
      }
    }

    // Check for Case 2
    const videoIndex1CreatedAt = videoIndex1?.createdAt
      ? new Date(videoIndex1.createdAt).getTime()
      : getLocalStorage("firstConditionTime");
    if (videoIndex1CreatedAt) {
      const case2Valid = videos.some((video) => {
        const {
          videoIndex,
          percent: percentWatched,
          progress: seekTime,
        } = video;
        return (
          videoIndex > 0 &&
          videoIndex < 6 &&
          currentTime > videoIndex1CreatedAt + 12 * 60 * 60 * 1000 &&
          percentWatched > 40 &&
          seekTime > 0.5
        );
      });
      if (case2Valid) {
        return true; // Case 2
      }
    }

    // Check for Case 3
    const videoIndex6 = videos.find((video) => video.videoIndex === 6);
    if (videoIndex6 || (user?.unlockVideo as number) >= 6) {
      return true; // Case 3
    }

    return false; // Default case if none of the conditions match
  }, [user?.watchedVideos]);

  return (
    <>
      {!isMobile && (
        <Flex
          as="nav"
          borderBottom="1px solid #EAECF0"
          height="60px"
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <img
            src="/images/viral-logo.webp"
            alt="viral-logo"
            height="18"
            width="107"
          />

          <Box
            border="1px solid #d9d9d9"
            display="inline-block"
            height="29px"
            mx={{
              base: "15px",
              md: "18px",
            }}
          />
          <Box
            p="6px 10px"
            background="#FEF3F2"
            color="#D92D20"
            fontSize="14px"
            fontWeight="500"
            lineHeight="17.31px"
            height="29px"
            borderRadius="9px"
            me="13px"
          >
            Five day fast start
          </Box>
          <NavbarProfile />
        </Flex>
      )}

      <Box backgroundColor="#F9FAFB">
        <div className="px-6 md:px-10">
          <Box width="100%" maxWidth="1110px" margin="0 auto" pt="30px">
            <FastStartVideos
              videos={videos.slice(1)}
              unlockVideo={unlockVideo}
              activeVideoIndex={activeVideoIndex - 1}
              setActiveVideoIndex={(idx) => handleSetActiveVideoIndex(idx + 1)}
            />

            <FastStartMainVideo
              isDisplayQualifyBtn={isDisplayQualifyBtn}
              activeVideoIndex={activeVideoIndex}
              selectedVideo={videos[activeVideoIndex]}
            />

            <FastStartProducts
              headline="Your purchased products"
              products={purchaseProducts}
            />

            <FastStartProducts
              headline="Your free bonuses"
              products={freeBonuses}
            />

            {/* https://viralprofits.slack.com/archives/C07KFNLKUMA/p1732463963520169 */}
            <BookCallPopUp
              // isOpen={isOpenBookCallPopUp}
              isOpen={false}
              onClose={handleCloseBookCallPopUp}
            />
          </Box>
        </div>
        <SuccessStory />
      </Box>

      <CheckoutModal
        amount={endless}
        isOpen={checkoutModal.isOpen}
        onClose={checkoutModal.onClose}
        header={
          <div className="mb-[-5px]">
            <h3 className="mb-[15px] text-center text-26 font-black md:mb-[25px]">
              The Endless Video Idea System
            </h3>
            <p className="mx-auto mb-[25px] max-w-[508px] text-center text-18 font-[450]">
              Save yourself time and generate unique video ideas for your
              channel in just 10 minutes. To keep earning, you need to stay
              creative and keep posting new videos. Jake's system automates the
              brainstorming process with a proven formula.
            </p>
            <p className="mx-auto max-w-[505px] text-center text-20 font-bold">
              Your card on file couldn't be processed 😔{" "}
              <br className="hidden md:block" />
              Please enter your billing info below:
            </p>
          </div>
        }
      />
    </>
  );
};

const useBookCallPopup = () => {
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const { getBookCallPopupStatus, setBookCallPopupStatus } =
    useBookCallPopupStorage();

  const watchedVideos =
    typeof user?.watchedVideos === "object"
      ? Object.values(user.watchedVideos)
      : user?.watchedVideos || [];

  const day1Video = watchedVideos?.find((video) => video.videoIndex === 1);
  const isWatchedDay1Video =
    day1Video?.completed &&
    dayjs(day1Video?.updatedAt).isValid() &&
    // Check if the day 1 video was watched and the last update was more than 24 hours ago
    dayjs(day1Video?.updatedAt).isBefore(dayjs().subtract(24, "hours"));

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // condition: if user watched day 1 video and popup is not opened before, open the popup
    if (isWatchedDay1Video && getBookCallPopupStatus() !== "opened") {
      setIsOpen(true);
      setBookCallPopupStatus("opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchedDay1Video]);

  return {
    isOpen,
    handleClose,
  };
};

// TODO: move this to a database
const useBookCallPopupStorage = () => {
  const getBookCallPopupStatus = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY.BOOK_CALL_POPUP);
  };

  const setBookCallPopupStatus = (status: "opened" | "closed") => {
    localStorage.setItem(LOCAL_STORAGE_KEY.BOOK_CALL_POPUP, status);
  };

  return { getBookCallPopupStatus, setBookCallPopupStatus };
};

// To maintain the state of ActiveVideoIndex
const useActiveVideoIndex = () => {
  const { user } = useAuth();
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);

  const handleSetActiveVideoIndex = (idx: number) => {
    setActiveVideoIndex(idx);
    localStorage.setItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY, idx.toString());
  };

  useEffect(() => {
    const index = localStorage.getItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY);
    if (index) {
      setActiveVideoIndex(
        ACTIVE_VIDEO_INDEX.includes(Number(index)) ? Number(index) : 0,
      );
    }
  }, []);

  return { activeVideoIndex, handleSetActiveVideoIndex };
};

export default MemberShipPage;
