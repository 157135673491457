import { OptInInputs } from "../components/SalesPageComponents/SpotForm";
import axios from "../libs/axios";
import { WatchedVideo } from "../types/type";

export const unlockNextVideo = () => {
  return axios.post("/user/unlock");
};

export const updateVideoProgress = (
  data: Pick<WatchedVideo, "percent" | "progress" | "videoIndex">,
) => {
  return axios.put("/user/watched-video", data);
};

export const fillSalesForm = (data: OptInInputs)=> {
  return axios.post("/user/fill-sales-form", data);
}