import { Image } from "@chakra-ui/react";
import SalesCard from "../../SalesCard";
import { cn } from "../../../utils/utils";
import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";

const MeetInstructor = () => {
  return (
    <>
      <div className="mt-[50px] flex items-center justify-center gap-2.5 md:mt-20 md:gap-[23px]">
        <Image
          src="/images/home/jake-tran-avt.webp"
          className="h-[53px] w-[52px] md:h-[80px] md:w-[80px]"
          loading="lazy"
        />
        <Image
          src="/images/home/jake-tran-sign.webp"
          className="h-[38px] w-[94px] md:h-[58px] md:w-[143px]"
          loading="lazy"
        />
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="mt-5 max-w-[255px] text-center text-26 font-medium md:mt-[30px] md:max-w-full md:text-44">
          Meet your instructor: <span className="font-black">Jake Tran</span>
        </div>
        <div
          className="mt-[15px] text-center text-20 font-bold-450 md:mt-5 md:text-26"
          style={{
            color: "rgba(0, 0, 0, 0.8)",
          }}
        >
          2M+ subscribers across 3 YouTube channels
        </div>
      </div>

      <SalesCard className={cn("mx-auto mt-7.5 w-full max-w-[920px]")} />

      <div className="relative mt-[30px] md:mt-10">
        <div className="absolute top-[100px] hidden min-[1440px]:inline-block">
          <Image
            src="/images/home/jake-with-two-dogs-bg.webp"
            className="h-[500px] w-[500px]"
            loading="lazy"
          />
        </div>
        <div className="absolute top-[100px] hidden min-[1440px]:inline-block">
          <Image
            src="/images/home/jake-with-two-dogs-bg.webp"
            className="h-[700px] w-[500px]"
            loading="lazy"
          />
        </div>

        <div className="absolute right-0 top-[178px] hidden min-[1440px]:inline-block">
          <Image
            src="/images/home/jake-archived-bg.webp"
            className="h-[700px] w-[500px]"
            loading="lazy"
          />
        </div>
        <div className="absolute right-0 top-[178px] hidden min-[1440px]:inline-block">
          <Image
            src="/images/home/jake-archived-bg.webp"
            className="h-[700px] w-[500px]"
            loading="lazy"
          />
        </div>

        {/* This image only show on mobile */}
        <div
          className="md:hidden"
          style={{
            backgroundImage: "url('/images/home/image-bg.webp')",
            backgroundSize: "100% 90%",
          }}
        >
          <Image
            src="/images/home/jake-with-two-dogs-mobile.webp"
            className="mx-auto -mb-2 h-[350px] w-[245px] md:hidden"
            loading="lazy"
          />
        </div>

        <div className="mx-auto max-w-[528px]">
          <StorytellingParagraph
            titleProps={{ className: "lg:max-w-[648px]" }}
            paragraphClassName="relative px-6"
          >
            <div className="absolute top-[195px] z-10 hidden -translate-x-full min-[1440px]:inline-block">
              <Image
                src="/images/home/jake-with-two-dogs.webp"
                className="h-[495px] w-[395px]"
                loading="lazy"
              />
            </div>

            <div className="absolute -left-[187px] top-[243px] hidden translate-x-full min-[1440px]:inline-block">
              <Image
                src="/images/home/jake-archived.webp"
                className="h-[600px] w-[598px]"
                loading="lazy"
              />
            </div>
            <p className="text-18 font-bold italic md:text-26">
              Hey, my name is Jake Tran…
            </p>
            <p>And seven years ago, I was no different…</p>
            <p>
              Just a regular student, went to college, and eventually dropped
              out…
            </p>
            <p>Then got stuck in a 9-5 job…</p>
            <p>Had no free time…</p>
            <p>And was desperately looking for ways to make more money.</p>
            <p>Of course… I didn’t know how YouTube worked…</p>
            <p>Never created a successful YouTube video…</p>
            <p>I didn't even know how YouTube itself works…</p>
            <p>But I eventually cracked the code.</p>
            <p>And now I share it with the world.</p>
          </StorytellingParagraph>
        </div>
        {/* This image only show on mobile */}
        <div
          className="md:hidden"
          style={{
            backgroundImage: "url('/images/home/image-bg.webp')",
            backgroundSize: "100% 90%",
          }}
        >
          <Image
            src="/images/home/jake-archived-mobile.webp"
            className="mx-auto -mt-2 h-[350px] w-[245px] md:hidden"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default MeetInstructor;
