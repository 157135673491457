import { Box } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { avatars } from "../../constants";
import { useIntersectionElement } from "../../hooks/useIntersectionElement";
import { cn } from "../../utils/utils";
import PassionButton from "../PassionButton";
import GetInstantParticipated from "./parts/GetInstantParticipated";
import GetInstantServices from "./parts/GetInstantServices";
import "./styles.css";

export interface GetInstantAccessProps
  extends ComponentPropsWithoutRef<"section"> {
  title: string;
  videoPlayer: ReactNode;
}

export const getInstantAccessIntersectingEvent = "getInstantAccessIntersecting";

const GetInstantAccess = ({
  title,
  videoPlayer,
  className,
  ...props
}: GetInstantAccessProps) => {
  const [ref, entry] = useIntersectionElement();

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("getInstantAccessIntersecting", {
        detail: entry?.isIntersecting,
      }),
    );
  }, [entry?.isIntersecting]);

  return (
    <section
      {...props}
      className={cn(
        "relative flex flex-col items-center justify-center pb-[49px] pt-8 text-center md:pb-[60px]",
        className,
      )}
    >
      <h1
        className={`md:w-5xl relative mx-auto mb-[9px] px-8 text-[28px] font-[900] leading-[37px] text-black xs:w-[380px] sm:w-[380px] md:mb-[18px] md:w-full md:px-16 md:text-[36px] md:leading-10 lg:max-w-6xl lg:text-44`}
      >
        {title}
      </h1>

      <p className="relative mx-auto mb-[25px] w-[350px] px-8 text-center text-[17px] font-[500] xxxs:w-[300px] md:mb-8.75 md:w-full md:max-w-5xl md:px-16 lg:mb-11 lg:max-w-6xl lg:text-24 xl:mb-[67px] 6xl:mb-24">
        All without ever showing your face or creating the videos yourself
      </p>

      <Box position="relative" w="full" mb="10">
        {videoPlayer}
      </Box>
      <div>
        <PassionButton ref={ref} className="mb-[13px] md:mb-[21px] md:mt-9" />

        <GetInstantParticipated
          items={avatars}
          className="mb-[28px] justify-center md:mb-10"
        />

        <GetInstantServices />
      </div>

      {/* Background gradient */}
      <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full 6xl:h-screen"></div>
    </section>
  );
};

export default GetInstantAccess;
