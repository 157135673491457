import { ROUTES } from "../constants";

const IGNORE_FOOTER_PAGE:string[] = [];

const SHOWING_WANNA_INSTANT_ACCESS_PAGE = [
  ROUTES.FIVE_DAYS,
  ROUTES.SALES,
  ROUTES.PASSION,
];

export const footerConfig = {
  ignorePages: IGNORE_FOOTER_PAGE,
  showingWannaInstantAccessPage: SHOWING_WANNA_INSTANT_ACCESS_PAGE,
};
