import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const usePageTracking = () => {

    const location = useLocation();

    // Extract the last part of the path and capitalize the first letter
    const pageName = location.pathname.split("/").pop();
    const formattedPageTitle = `Viral Profits${pageName ? " | " + pageName.charAt(0).toUpperCase() + pageName.slice(1) : ""}`;

    useEffect(() => {
        document.title = formattedPageTitle
        // Send the pageview event with the formatted title
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        });
    }, [location.pathname]);
};

export default usePageTracking;
