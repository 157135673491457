import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FullScreenModal } from "../../ui";
import { useCheckout } from "../../../providers/CheckoutProvider";
import { useEffect } from "react";
import CloseIcon from "../../CloseIcon";
import SpinningModal from "../Loading";

export const PaymentDeclineModal = ({
  showMobileOnly,
  openCallback,
  closeCallback,
}: {
  showMobileOnly?: boolean;
  openCallback?: () => void;
  closeCallback?: () => void;
}) => {
  const [isSmallerMd] = useMediaQuery("(max-width: 768px)");
  const { paymentError, setPaymentError, isProcessing } = useCheckout();
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  useEffect(() => {
    if (paymentError) {
      onOpen();
      openCallback?.();
    }
  }, [onOpen, paymentError, openCallback]);

  return (
    <>
      <SpinningModal open={isProcessing} minClosingTime={800} />
      <FullScreenModal
        className="bg-[#0000008A]"
        open={showMobileOnly ? isSmallerMd && isOpen : isOpen}
      >
        <div className="p-3">
          <div className="m-auto flex max-w-[720px] flex-col gap-5 bg-white p-5 text-center xxxs:rounded-[14px] md:rounded-[20px]">
            {!isSmallerMd && (
              <div
                className="flex cursor-pointer justify-end"
                onClick={() => {
                  setPaymentError("");
                  onClose();
                  closeCallback?.();
                }}
              >
                <CloseIcon />
              </div>
            )}
            <div className="mx-auto mt-[20px] text-center text-26 font-black xxxs:w-[300px] md:w-full md:text-40">
              Transaction not completed
            </div>
            <div className="text-pretty px-[20px]">
              <div className="mb-8 mt-4 text-16 font-[400] leading-5 text-[#424242] md:text-20">
                There was a problem with your card and the charge was not
                completed. Please contact your bank for more information. To
                complete your booking, please try again with the same card or an
                alternative payment method.
              </div>
            </div>
            <button
              className="m-auto mb-[20px] w-[305px] rounded-2xl bg-[#D92D20] px-[40px] py-[20px] text-center text-16 font-bold text-white md:w-[618px] md:text-24"
              onClick={() => {
                setPaymentError("");
                onClose();
                closeCallback?.();
              }}
            >
              Try again
            </button>
          </div>
        </div>
      </FullScreenModal>
    </>
  );
};
