import axios from "../libs/axios";
import {
  CheckoutPayload,
  CreateOrderPayload,
  PaymentIntentPayload,
  RecurringPayload,
  UpsellPayload,
} from "../types/type";

export const createPaymentIntent = async (payload: PaymentIntentPayload) => {
  try {
    const res = await axios.post("/user/payment-intent", payload);
    return res.data;
  } catch (error) {
    console.log("Error creating payment intent", error);
  }
};

export const checkout = async (payload: CheckoutPayload) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await axios.post("/user/checkout", { ...payload, timezone });
};

export const upsell = async (payload: UpsellPayload) => {
  return await axios.post("/user/upsell", payload);
};

export const recurring = async (payload: RecurringPayload) => {
  return await axios.post("/user/recurring", payload);
};

export const reEnterPayment = async (payload: Partial<CheckoutPayload>) => {
  return await axios.post("/user/payment/re-enter", payload);
};

export const createOrder = async (payload: CreateOrderPayload) => {
  try {
    const res = await axios.post("/order", payload);
    return res.data;
  } catch (error) {
    console.error("Error creating order", error);
    throw error;
  }
};

export const getPaymentIntent = async (paymentIntentId: string) => {
  try {
    const res = await axios.get(`/order/payment-intent/${paymentIntentId}`);
    return res.data;
  } catch (error) {
    console.error("Error fetching payment intent", error);
    throw error;
  }
};