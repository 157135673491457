import { CheckboxGroup, Image } from "@chakra-ui/react";
import { shutdown } from "@intercom/messenger-js-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import checkout from "../assets/images/checkout.webp";
import {
  BillingInformation,
  CheckoutCountdown,
  CheckoutHeader,
  CheckoutSteps,
  ChooseYourPrice,
  CustomerInformation,
  GuarantyDays,
  LoaderModal,
  MostPopularOption,
  SummaryPrice,
} from "../components/CheckoutPageSecond";
import { PaymentDeclineModal } from "../components/CheckoutPage/PaymentDeclineModal";
import { ReminderModal } from "../components/CheckoutPage/ReminderModal/ReminderModal";
import { useCheckout } from "../providers/CheckoutProvider";
import { gtag_report_conversion, GTAGEvent } from "../utils/gtag";
import useMagicPrice from "../components/CountDown/useMagicPrice";
// import { decodeValue } from "../libs/common";
import Cookies from "js-cookie";
import { LOCAL_STORAGE_KEY } from "../constants";
import { GraySection } from "../components/ui";
import { useWatch } from "react-hook-form";
import { getCountryFromPhoneNumber, parsePhoneNumber } from "../utils/utils";

const CheckoutPageSecond = () => {
  const { base } = useMagicPrice();

  const checkoutPriceOptions = base;
  // salesExpire === "0" && isFinishQuiz === "0" ? 3 : base;

  const [hasOpen, setHasOpen] = useState(false);
  const [isLoaderSuccess, setLoaderSuccess] = useState(false);
  const {
    control,
    errors,
    optionValues: value,
    getCheckboxProps,
    watch,
    setValue,
    isProcessing,
    paymentError,
    getValues,
    handleCheckout,
    setPaymentError,
  } = useCheckout();
  const firstName = useWatch({ control, name: "firstName" });

  // on page load
  useEffect(() => {
    gtag_report_conversion(GTAGEvent.BEGIN_CHECKOUT_FRONT_END, {
      value: checkoutPriceOptions,
    });
  }, []);

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA) || "{}",
    );

    const cookieFirstName = Cookies.get("viralprofits_firstName");
    const cookieLastName = Cookies.get("viralprofits_lastName");
    const cookieEmail = Cookies.get("viralprofits_email");
    const cookiePhoneNumber = parsePhoneNumber(
      Cookies.get("viralprofits_phone") || "",
    );
    const cookieCountry = getCountryFromPhoneNumber(
      Cookies.get("viralprofits_phone") || "",
    );

    if (cookieCountry) {
      const saleUserData = localStorage.getItem(
        LOCAL_STORAGE_KEY.SALE_USER_DATA,
      );

      if (saleUserData) {
        const saleData = JSON.parse(saleUserData);
        saleData.country = cookieCountry;
        localStorage.setItem(
          LOCAL_STORAGE_KEY.SALE_USER_DATA,
          JSON.stringify(saleData),
        );
      }
    }

    setValue("country", userData?.country);
    setValue("firstName", cookieFirstName || userData?.firstName);
    setValue("lastName", cookieLastName || userData?.lastName);
    setValue("email", cookieEmail || userData?.email);
    setValue("phone", cookiePhoneNumber || userData?.phone);
    setValue("urlPage", window.location.href);
  }, [setValue]);

  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    };

    const handleBeforeUnload = () => {
      shutdown();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    handleScroll();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      shutdown();
    };
  }, [isLoaderSuccess]);

  const amount = value.reduce(
    (acc, option) => Number(acc) + Number(option),
    0,
  ) as number;

  const email = useMemo(() => {
    const queryData = getValues("email");
    if (typeof queryData === "string") {
      return queryData;
    } else {
      return "";
    }
  }, []);

  const handleCloseReminderModalCallback = useCallback(() => {
    setHasOpen(true);
  }, []);

  const callbackSuccessLoader = useCallback(() => {
    setLoaderSuccess(true);
  }, []);

  return (
    <>
      {/* Payment decline and spinning modal */}
      <PaymentDeclineModal />
      {!isProcessing && !paymentError && (
        <ReminderModal
          firstName={firstName}
          onCloseModalCallback={handleCloseReminderModalCallback}
          isLoaderSuccess={isLoaderSuccess}
          hasOpen={hasOpen}
        />
      )}
      <LoaderModal
        callbackSuccessLoader={callbackSuccessLoader}
        amount={amount}
        email={email}
      />
      <CheckboxGroup colorScheme="green" defaultValue={[base]}>
        <CheckoutHeader />
        <CheckoutCountdown />
        <CheckoutSteps />

        <div className="container-1200 mt-[30px] xxxs:mb-[60px] lg:mb-[100px] lg:mt-0 lg:scale-95 xl:mt-[69px] xl:scale-100">
          <div className="grid grid-cols-1 gap-10 lg:flex">
            {/* left/top column */}
            <div className="p-2.5 lg:min-w-[554px] lg:max-w-[554px] xl:p-0">
              <CustomerInformation
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                amount={amount}
              />
              <ChooseYourPrice getCheckboxProps={getCheckboxProps} />
              <GraySection className="-p-3 md:-p-0 mt-[25px] lg:p-0">
                <BillingInformation
                  amount={amount}
                  handleCheckout={handleCheckout}
                  control={control}
                  errors={errors}
                  isProcessing={isProcessing}
                  setPaymentError={setPaymentError}
                />
                <div className="mx-auto h-[1px] max-w-[94%] bg-[#E7E7E7] lg:hidden"></div>
                <GuarantyDays />
              </GraySection>
            </div>
            {/* right/bottom column */}
            <div className="p-2.5 xl:p-0">
              <div className="">
                <h3 className="font-black xxxs:text-center xxxs:text-24 xs:mb-2 md:text-28 lg:mb-6 lg:text-left">
                  Here’s what you’re getting
                </h3>
                <div className="w-full px-[20px] py-[4px] md:px-[14px] md:py-[7px]">
                  <Image
                    src={checkout}
                    alt="Here's what you're getting"
                    width={{
                      base: "330px",
                      md: "577px",
                    }}
                    height={{
                      base: "158px",
                      md: "278px",
                    }}
                    objectFit="cover"
                    margin="0 auto"
                  />
                </div>
              </div>
              <MostPopularOption getCheckboxProps={getCheckboxProps} />
              <SummaryPrice />
            </div>
          </div>
        </div>
      </CheckboxGroup>
    </>
  );
};

export default CheckoutPageSecond;
