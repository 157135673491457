import CryptoJS from "crypto-js";

export const encodeValue = (value: string | number): string => {
  const secretKey = import.meta.env.VITE_AES_SECRET_KEY;
  const encrypted = CryptoJS.AES.encrypt(
    value.toString(),
    secretKey,
  ).toString();
  return encrypted;
};

export const decodeValue = (encryptedValue: string): string => {
  const secretKey = import.meta.env.VITE_AES_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};
