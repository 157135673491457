import { useState, useEffect } from "react";
import { IHeadlessComponent } from "../../ui/BaseHeadless/base.headless";
import { getLocalStorage, setLocalStorage } from "../../../utils/utils";
import { useCountDownStore } from "./useCountDownStore";

export interface CheckoutTimerProps
  extends IHeadlessComponent<{
    minutes: number;
    seconds: number;
    addMoreMinutes: (number: number) => void;
  }> {
  initialMinutes?: number;
  initialSeconds?: number;
  isSaveToStorages?: boolean;
  minuteStorageKey?: string;
  secondStorageKey?: string;
}

const CountdownLogic = (props: CheckoutTimerProps) => {
  const { setExpired } = useCountDownStore();
  const {
    children,
    initialMinutes = 0,
    initialSeconds = 0,
    isSaveToStorages,
    minuteStorageKey,
    secondStorageKey,
  } = props;

  const minuteSgKey = minuteStorageKey || "_dm";
  const secondSgKey = secondStorageKey || "_ds";
  const endCountdown = "_ecd";
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const endCountdownValue = getLocalStorage(endCountdown);

  useEffect(() => {
    if (isSaveToStorages || endCountdownValue === "1") {
      const storedMinutes = getLocalStorage(minuteSgKey);
      const storedSeconds = getLocalStorage(secondSgKey);

      if (storedMinutes !== null && storedSeconds !== null) {
        setMinutes(Number(storedMinutes));
        setSeconds(Number(storedSeconds));
      } else if(endCountdownValue === "1") {
        setLocalStorage(minuteSgKey, JSON.stringify(0));
        setLocalStorage(secondSgKey, JSON.stringify(0));
      } else {
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
        setLocalStorage(minuteSgKey, JSON.stringify(initialMinutes));
        setLocalStorage(secondSgKey, JSON.stringify(initialSeconds));
      }
    }
    setIsInitialLoad(false);
  }, [isSaveToStorages, minuteSgKey, secondSgKey, endCountdownValue]);

  useEffect(() => {
    if (isInitialLoad) return; // Không bắt đầu đếm ngược nếu là lần đầu load

    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        if (isSaveToStorages) {
          setLocalStorage(secondSgKey, JSON.stringify(seconds - 1));
        }
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setLocalStorage(endCountdown, JSON.stringify(1));
          setExpired(true);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);

          if (isSaveToStorages) {
            setLocalStorage(secondSgKey, 59);
            setLocalStorage(minuteSgKey, JSON.stringify(minutes - 1));
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [
    seconds,
    minutes,
    isSaveToStorages,
    secondSgKey,
    minuteSgKey,
    isInitialLoad,
  ]);

  const addMoreMinutes = (additionalMinutes: number) => {
    setMinutes((prev) => prev + additionalMinutes);
  };

  return children({
    minutes,
    seconds,
    addMoreMinutes,
  });
};

export default CountdownLogic;
