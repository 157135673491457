import { useEffect, useState } from "react";
import {
  Text,
  Button,
  Wrap,
  WrapItem,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import BoxLayout from "./BoxLayout";
import CTAButton from "../CTAButton";
import CalculationBox from "./caculationBox";
import { Niche, SelectedValue, ViewCount } from "../../types/type";
import { handleProfitCalculation } from "./handleCalculation";
import { currencyFormatter } from "../../utils/currencyformatter";
import { LOCAL_STORAGE_KEY, ROUTES } from "../../constants";
import { useAuth } from "../../providers/AuthProvider";

const ProfitCalculatorComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showQualifyButton, setShowQualifyButton] = useState<boolean>(false);

  // Data for niches and view counts
  const niches: Record<string, Niche> = {
    "Documentaries and video essays": { min: 5, max: 10 },
    "Entertainment and pop culture": { min: 3, max: 6 },
    "Beauty and fashion": { min: 2.5, max: 7.5 },
    Tech: { min: 5, max: 10 },
    Food: { min: 5, max: 7.5 },
    Educational: { min: 5, max: 10 },
    "Finance and investing": { min: 6, max: 12.5 },
    "Celebrity culture": { min: 5, max: 7.5 },
    "Health and fitness": { min: 3.5, max: 7.5 },
    "Travel and lifestyle": { min: 5, max: 10 },
    Crypto: { min: 10, max: 30 },
    Gaming: { min: 3.5, max: 6.5 },
  };

  const viewCounts: Record<string, ViewCount> = {
    "50k": { min: 1500, max: 2000, value: 50 },
    "100k": { min: 3500, max: 5000, value: 100 },
    "150k": { min: 5000, max: 5500, value: 150 },
    "200k": { min: 5500, max: 6000, value: 200 },
    "300k": { min: 6000, max: 7500, value: 300 },
    "500k": { min: 7500, max: 10000, value: 500 },
    "750k": { min: 10000, max: 12000, value: 750 },
    "1M+": { min: 12000, max: 20000, value: 1000 },
  };

  const months = [2, 4, 6, 8, 10, 12];

  // State management with correct types
  const [selectedValue, setSelectedValue] = useState<SelectedValue>({
    sponsorValue: { min: 1500, max: 2000, value: 50 },
    nicheValue: { min: 5, max: 10 },
    selectedViewCount: "50k",
    selectedNiche: "Documentaries and video essays",
    videosPerMonth: 2,
  });

  // Handle niche selection
  const handleNicheSelection = (index: number) => {
    const nicheKeys = Object.keys(niches);
    const selectedNiche = nicheKeys[index];
    const nicheValue = niches[selectedNiche];
    setSelectedValue((prev) => ({
      ...prev,
      nicheValue,
      selectedNiche,
    }));
  };

  // Handle view count slider change
  const handleViewCountChange = (index: number) => {
    const viewCountKeys = Object.keys(viewCounts);
    const selectedViewCount = viewCountKeys[index];
    const sponsorValue = viewCounts[selectedViewCount];

    setSelectedValue((prev) => ({
      ...prev,
      sponsorValue,
      selectedViewCount,
    }));
  };

  // Handle videos per month slider change
  const handleVideosPerMonthChange = (value: number) => {
    setSelectedValue((prev) => ({
      ...prev,
      videosPerMonth: value,
    }));
  };

  const { minYoutubeProfit, maxYoutubeProfit, minTotalProfit, maxTotalProfit } =
    handleProfitCalculation(selectedValue);

  useEffect(() => {
    setShowQualifyButton(
      localStorage.getItem(LOCAL_STORAGE_KEY.IS_QUALIFY_BUTTON) === "true",
    );
  }, []);

  return (
    <div className="p-2 py-4 md:py-6">
      {/* Step 1: Select Niche */}
      <BoxLayout>
        <Text fontSize="20px" fontWeight="700" mb={4}>
          <span className="font-black">Step 1:</span> Select a niche
        </Text>
        <Wrap spacing={3}>
          {Object.keys(niches).map((niche, index) => (
            <WrapItem key={index}>
              <Button
                variant="outline"
                borderRadius="15px"
                backgroundColor={
                  selectedValue.selectedNiche === niche
                    ? "transparent"
                    : "#FFFFFF"
                }
                border="1px"
                borderColor={
                  selectedValue.selectedNiche === niche ? "#D92D20" : "#EAECF0"
                }
                fontSize={["14px", "16px", "18px"]}
                fontWeight="500"
                color={
                  selectedValue.selectedNiche === niche ? "#D92D20" : "#000000"
                }
                onClick={() => handleNicheSelection(index)}
              >
                {niche}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </BoxLayout>

      {/* Step 2: Number of videos per month */}
      <BoxLayout bgColor="#FFFFFF">
        <Text fontSize="20px" fontWeight="700" mb={8}>
          <span className="font-black">Step 2:</span> How many videos do you
          want to publish per month?
        </Text>
        <Box
          paddingLeft={"2px"}
          maxWidth={"1108px"}
          className="xxxs:w-[96%] md:w-[98%] lg:w-full"
        >
          <Slider
            defaultValue={2}
            min={2}
            max={12}
            step={2}
            colorScheme="red"
            onChange={handleVideosPerMonthChange}
          >
            <SliderTrack bg="#FFEDED" height="10px" borderRadius="12px">
              <SliderFilledTrack bg="#D92D20" />
            </SliderTrack>
            <SliderThumb
              boxSize="22px"
              backgroundColor="#D92D20"
              _focus={{
                boxShadow: "0px 0px 0px 4px rgba(217, 45, 32, 0.33)",
              }}
            />
          </Slider>
        </Box>
        {/* Labels for each step */}
        <Flex justifyContent="space-between">
          {months.map((step, index) => (
            <Text
              key={index}
              fontSize={["14px", "18px", "24px"]}
              fontWeight="500"
            >
              {index === months.length - 1 ? `${step}+` : step}
            </Text>
          ))}
        </Flex>
      </BoxLayout>

      {/* Step 3: Select average video view count */}
      <BoxLayout>
        <Text fontSize="20px" fontWeight="700" mb={8}>
          <span className="font-black">Step 3:</span> Select average video view
          count
        </Text>
        <Box
          paddingLeft={"2px"}
          maxWidth={"1108px"}
          className="xxxs:w-[96%] md:w-[98%] lg:w-full"
        >
          <Slider
            defaultValue={0}
            min={0}
            max={Object.keys(viewCounts).length - 1}
            step={1}
            colorScheme="red"
            onChange={handleViewCountChange}
          >
            <SliderTrack bg="#FFEDED" height="10px" borderRadius="12px">
              <SliderFilledTrack bg="#D92D20" />
            </SliderTrack>
            <SliderThumb
              boxSize="22px"
              backgroundColor="#D92D20"
              _focus={{
                boxShadow: "0px 0px 0px 4px rgba(217, 45, 32, 0.33)",
              }}
            />
          </Slider>
        </Box>

        {/* Labels for each view count */}
        <Flex justifyContent="space-between">
          {Object.keys(viewCounts).map((step, index) => (
            <Text
              key={index}
              fontSize={["14px", "18px", "24px"]}
              fontWeight="500"
            >
              {step}
            </Text>
          ))}
        </Flex>
      </BoxLayout>

      {/* Calculation Section */}
      <Box bg="#FFFFFF" px={["16px", "0px", "0px"]}>
        <Flex
          direction={["column", "column", "row"]}
          justify="center"
          align="center"
          gap={[0, 0, 8]}
          maxWidth={"1024px"}
          mx="auto"
        >
          <CalculationBox
            title="Earnings from YouTube"
            amount={`${currencyFormatter.format(minYoutubeProfit)} - ${currencyFormatter.format(maxYoutubeProfit)}`}
          />

          <Text fontSize="48px" fontWeight="300" color="#343330" mt={[0, 0, 8]}>
            +
          </Text>

          <CalculationBox
            title="Earnings from sponsors"
            amount={`${currencyFormatter.format(selectedValue.sponsorValue.min)} - ${currencyFormatter.format(selectedValue.sponsorValue.max)}`}
          />

          <Text fontSize="48px" fontWeight="300" color="#343330" mt={[0, 0, 8]}>
            =
          </Text>

          <CalculationBox
            title="Total earnings"
            amount={`${currencyFormatter.format(minTotalProfit)} - ${currencyFormatter.format(maxTotalProfit)}`}
          />
        </Flex>

        <Text
          mt={4}
          fontSize="14px"
          fontWeight="300"
          textAlign="center"
          display={{ base: "none", md: "block" }}
        >
          Average estimates based on 2024 data for a YouTube channel in{" "}
          {selectedValue.selectedNiche} niche publishing{" "}
          {selectedValue.videosPerMonth === 12
            ? "12+"
            : selectedValue.videosPerMonth}{" "}
          videos per month with an average of {selectedValue.selectedViewCount}{" "}
          views.
        </Text>
      </Box>

      {showQualifyButton && user && (
        <Box maxWidth={"1176"} mx="auto">
          <CTAButton onClick={() => navigate(ROUTES.SEE_IF_YOU_QUALIFY)} />
        </Box>
      )}
    </div>
  );
};

export default ProfitCalculatorComponent;
