import CountdownLogic from "../components/ui/CountdownLogic";
import { ROUTES } from "../constants";

export const OtosuPage = () => {
  return (
    <div className="relative h-[100vh] w-full">
      <div className="mx-auto flex h-full max-w-[548px] flex-col items-center justify-center px-5 text-center md:px-0">
        <div className="text-28 font-black md:text-48">🎉</div>
        <div className="mt-5 text-28 font-black md:mt-[22px] md:text-48">
          Congrats, your upgrade was successful!
        </div>
        <CountdownLogic initialSeconds={5}>
          {({ seconds }) => {
            if (seconds <= 1) {
              // https://viralprofits.slack.com/archives/C07KFNLKUMA/p1732463963520169
              window.location.href = ROUTES.MEMBERSHIP;
            }
            return (
              <div className="mt-5 text-20 font-black md:mt-[30px] md:text-32">
                Redirecting you in {seconds}
              </div>
            );
          }}
        </CountdownLogic>
        <div className="mt-2 text-16 font-bold-450 md:mt-4 md:text-18">
          If you're not automatically redirected{" "}
          {/* https://viralprofits.slack.com/archives/C07KFNLKUMA/p1732463963520169 */}
          <a href={ROUTES.MEMBERSHIP}>
            <span className="text-[#D92D20]">click here</span>
          </a>
        </div>
      </div>
      <div className="green-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
    </div>
  );
};
