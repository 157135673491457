export const countryAllowZeroDialCode = [
  "972",
  "244",
  "229",
  "226",
  "257",
  "237",
  "236",
  "235",
  "269",
  "242",
  "243",
  "240",
  "291",
  "251",
  "241",
  "220",
  "233",
  "245",
  "225",
  "254",
  "266",
  "231",
  "261",
  "265",
  "223",
  "222",
  "230",
  "258",
  "264",
  "227",
  "234",
  "250",
  "239",
  "221",
  "248",
  "232",
  "252",
  "27",
  "211",
  "249",
  "268",
  "255",
  "228",
  "256",
  "260",
  "263",
];
