import { show } from "@intercom/messenger-js-sdk";
import { PhoneIcon, DiamondIcon, MailIcon } from "../../assets/icons";
import { initIntercom } from "../../libs/intercom";
import { FlagDetection } from "../ui/FlagDetection";

export const CheckoutHeader = () => {
  const handleInstantInitIntercom = () => {
    if (!window.Intercom) {
      initIntercom();

      if (window.Intercom) {
        show();
      }
    }
  };

  return (
    <div className="w-full bg-[#D92D20] p-4">
      <div className="container-1200 flex flex-col items-center justify-between text-14 font-medium uppercase text-white lg:flex-row">
        <div className="flex items-center gap-[9px]">
          <span className="mb-1 text-lg">
            <PhoneIcon />
          </span>
          <div>ORDER BY PHONE: +1 602-638-2627</div>
          {/* Flag */}
          <FlagDetection className="text-[18px] lg:!hidden" />
        </div>
        <div className="hidden items-center gap-[9px] lg:flex">
          <span className="mb-1">
            <DiamondIcon />
          </span>
          EXCLUSIVE OFFER FOR:
          <FlagDetection className="hidden max-h-[18px] items-center justify-center text-[18px] lg:mb-1 lg:flex" />
        </div>
        <div
          className="mt-3 flex cursor-pointer items-center gap-[9px] lg:mt-0"
          id="contact-us"
          onClick={handleInstantInitIntercom}
        >
          <span className="mb-1">
            <MailIcon />
          </span>
          <span className="text-14 font-medium">
            NEED HELP?{" "}
            <span className="border-b-2">CLICK HERE TO CONTACT US</span>
          </span>
        </div>
      </div>
    </div>
  );
};
