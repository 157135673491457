export const ArrowBack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00006 11H17.5861L12.2931 5.70697L13.7071 4.29297L21.4141 12L13.7071 19.707L12.2931 18.293L17.5861 13H3.00006V11Z"
        fill="white"
      />
    </svg>
  );
};
