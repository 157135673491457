import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import Payment from "payment";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { addPaymentInfo, lead, purchase } from "../apis/conversion.api";
import { countryAllowZeroDialCode } from "../constants/country-code";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertNewlinesToBreaks(text: string) {
  return text.replace(/\n/g, "<br>");
}

export const checkIOS = () => {
  const userAgent = window.navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isIOS || isSafari;
};

export const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};

export const decodeQueryParams = (encodedParams: string) => {
  const paddedParams =
    encodedParams + "===".slice(0, (4 - (encodedParams.length % 4)) % 4);

  // Decode from base64
  const jsonString = atob(paddedParams);
  const parsedParams = JSON.parse(jsonString);

  return {
    token: parsedParams.t, // t -> token
    userId: parsedParams.u, // u -> userId
    unlockVideo: parsedParams.v, // v -> unlockVideo
    isUnlockVideo: parsedParams.i, // i -> isUnlockVideo
  };
};

export const generateMagicKey = (isFillQuiz: boolean = false) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  const usedChars = new Set();

  const pair = !isFillQuiz
    ? Math.random() < 0.5
      ? ["A", "a"]
      : ["B", "b"]
    : ["A", "a"];
  key += pair[0];
  usedChars.add(pair[0]);

  key += pair[1];
  usedChars.add(pair[1]);

  while (key.length < 15) {
    const randomChar = chars[Math.floor(Math.random() * chars.length)];
    if (!usedChars.has(randomChar)) {
      key += randomChar;
      usedChars.add(randomChar);
    }
  }

  key = key
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  if (pair[0] === "A") {
    key = key.replace(/[bB]/g, "").slice(0, 15);
  } else {
    key = key.replace(/[aA]/g, "").slice(0, 15);
  }

  return key;
};

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export const formatCVC = (value: string) => {
  const clearValue = clearNumber(value);
  const maxLength = 4;

  return clearValue.slice(0, maxLength);
};

export const formatExpirationDate = (
  value: string,
  validCallBack: () => void,
  errorCallback: () => void,
) => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    const month = Number(clearValue.slice(0, 2));
    const year = Number(clearValue.slice(2, 4));

    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    if (
      month > 12 ||
      year < currentYear ||
      (year === currentYear && month < currentMonth)
    ) {
      errorCallback();
    } else {
      validCallBack();
    }

    return `${clearValue.slice(0, 2)} / ${year}`;
  }

  return clearValue;
};

export const setLocalStorage = (key: string, value: unknown) => {
  try {
    if (!value) {
      localStorage.removeItem(key);
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    localStorage.setItem(key, "");
  }
};

export const getLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);

    return null;
  } catch (error) {
    return null;
  }
};

export const setSessionStorage = (key: string, value: unknown) => {
  try {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    sessionStorage.setItem(key, "");
  }
};

export const getOSInfo = () => {
  const userAgent = window.navigator.userAgent;
  let os = "Unknown";

  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  else if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  else if (userAgent.indexOf("Android") !== -1) os = "Android";
  else if (userAgent.indexOf("like Mac") !== -1) os = "iOS";

  return os;
};

export const getIPAddressAndCountry = async () => {
  try {
    const response = await fetch("https://api.ipbase.com/v1/json");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error when get IP and country:", error);
    return "Can not get IP and country";
  }
};

export const getIPAddress = async () => {
  try {
    const ipAddressAndCountry = await getIPAddressAndCountry();
    return ipAddressAndCountry?.ip;
  } catch (error) {
    console.error("Error when get IP:", error);
    return "Can not get IP";
  }
};

export const getCountryFromIP = async () => {
  try {
    const ipAddressAndCountry = await getIPAddressAndCountry();
    return ipAddressAndCountry?.country_name;
  } catch (error) {
    console.error("Error when get country:", error);
    return "Can not get country";
  }
};

export const getCountryCodeFromIP = async () => {
  try {
    const ipAddressAndCountry = await getIPAddressAndCountry();
    return ipAddressAndCountry?.country_code;
  } catch (error) {
    console.error("Error when get country code:", error);
    return "Can not get country code";
  }
};

export const handleSendLead = async (email: string, first_name: string) => {
  const payload = {
    client_ip_address: await getIPAddress(),
    client_user_agent: getOSInfo(),
    email: email,
    first_name,
  };

  const response = await lead(payload);
  console.log("Lead response:", response);
};

export const sendPaymentInfor = async (
  clientIpAddress: string,
  clientUserAgent: string,
  firstName: string,
  lastName: string,
  phone: string,
  zip: string,
  email: string,
  pageUrl: string,
) => {
  const payload = {
    client_ip_address: clientIpAddress,
    client_user_agent: clientUserAgent,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    zip_code: zip,
    email,
    pageUrl: pageUrl,
  };
  try {
    const response = await addPaymentInfo(payload);
    console.log("Add payment info response:", response);
  } catch (error) {
    console.error(error);
  }
};

export const sendPurchase = async (
  clientIpAddress: string,
  clientUserAgent: string,
  firstName: string,
  lastName: string,
  phone: string,
  zip: string,
  email: string,
  pageUrl: string,
  amount: string,
) => {
  const payload = {
    client_ip_address: clientIpAddress,
    client_user_agent: clientUserAgent,
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    zip_code: zip,
    email,
    value: amount,
    page_url: pageUrl,
  };

  try {
    const response = await purchase(payload);
    console.log("Add payment info response:", response);
  } catch (error) {
    console.error(error);
  }
};

export const getServerURL = () => {
  return import.meta.env.PROD
    ? import.meta.env.VITE_SERVER_URL_PROD
    : import.meta.env.VITE_SERVER_URL_DEV;
};

export const formatPhoneNumber = (
  dialCode: string,
  phone: string,
) => {

  // If the country allows leading zero in phone numbers, keep the phone number as is
  const formPhoneFormat = countryAllowZeroDialCode.includes(dialCode)
    ? phone // Keep the number unchanged
    : phone.startsWith("0") ? phone.slice(1) : phone; // Otherwise, remove the leading zero if it exists

  // Add the country dial code in front of the formatted phone number
  const phoneSubmit = "+" + dialCode + formPhoneFormat;

  return phoneSubmit;
};

export const sendTrackingPurchase = async (
  item: string | undefined,
  price: number | string,
  title: string,
) => {
  if (window.heap && window.heap.track) {
    window.heap.track(title, { dollars: price, item: item });
  }
};

export const sendIdentification = async (
  email: string,
) => {
  if (window.heap && window.heap.identify) {
    window.heap.identify(email);
  }
};

export const parsePhoneNumber = (phoneNumber: string) => {
  const parsed = parsePhoneNumberFromString(phoneNumber);
  return parsed ? parsed.nationalNumber : phoneNumber;
};

export const getCountryFromPhoneNumber = (phoneNumber: string) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
    return null;
  }
  return parsedPhoneNumber.country?.toLocaleLowerCase();
};
