import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

const FastStartLinkSection: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      {!!user && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => navigate("/fast-start")}
          className="cursor sticky left-0 right-0 top-0 z-50 flex h-[58px] items-center justify-center bg-[#FFF1F1] px-[26px] text-center text-14 font-bold text-[#010101] md:text-16"
        >
          <p>
            🔥 <u className="underline-offset-[2px]">Click here</u> to continue
            watching the 5-Day Fast Start before it disappears! 🔥
          </p>
        </div>
      )}
    </>
  );
};

export default FastStartLinkSection;
