import { useEffect } from "react";
import { useCountDownStore } from "../components/CheckoutPage/CountdownLogic/useCountDownStore";
import {
  CloseWarning,
  FirstStoryTellingSection,
  IntroSection,
  Offers,
  OpenLetter,
  SecondStoryTellingSection,
  SevenFigureLaunchpad,
  TestimonialSection,
  WhyTheSecondOptionMoreExpensive,
} from "../components/UpSell";
import { Guarantee } from "../components/UpSell/Guarantee";
import Cookies from "js-cookie";
import { COOKIE_KEY } from "../constants";

const UpSellPage = () => {
  const { isCountdownExpired, setExpired } = useCountDownStore();

  // if set, hide close warning, timer and make sure page loading all at once
  const OTO1_LOGIN = Cookies.get(COOKIE_KEY.OTO1_LOGIN) !== undefined;

  useEffect(() => {
    if (OTO1_LOGIN) {
      setExpired(true);
    }
  }, []);

  return (
    <>
      {!OTO1_LOGIN && <CloseWarning />}
      <IntroSection hideTimer={OTO1_LOGIN} />
      {isCountdownExpired && (
        <>
          <OpenLetter />
          <FirstStoryTellingSection />
          <TestimonialSection />
          <SecondStoryTellingSection />
          <SevenFigureLaunchpad
            subHeading="Make your videos viral and start profiting in just 3-4 weeks"
            startingText={
              <span className="font-semibold text-[#D92D20]">$197</span>
            }
          />
          <Offers />
          <WhyTheSecondOptionMoreExpensive />
          <Guarantee />
        </>
      )}
    </>
  );
};

export default UpSellPage;
