import { Heading, StyleProps } from "@chakra-ui/react";

type FastStartHeadLineProps = {
  text: string;
} & StyleProps;

const FastStartHeadLine = ({ text, ...styleProps }: FastStartHeadLineProps) => {
  return (
    <Heading
      className="pl-6 !text-22 md:pl-6 md:!text-26 xl:pl-0"
      mt="40px"
      mb="20px"
      fontWeight="900"
      {...styleProps}
    >
      {text}
    </Heading>
  );
};

export default FastStartHeadLine;
