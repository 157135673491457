/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentPropsWithoutRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { cn } from "../../../utils/utils";
import InputErrorMessage from "../../InputErrorMessage";
import {
  CountryInput,
  CountryInputProps,
  PHONE_NUMBER_REGEX,
  PhoneNumberInput,
} from "../Input";

export interface PhoneWithCountryFieldProps
  extends ComponentPropsWithoutRef<"div"> {
  phoneName: string;
  countryName: string;
  label?: string;
  placeholder?: string;

  phoneInputProps?: Omit<ComponentPropsWithoutRef<"input">, "placeholder">;
  countryInputProps?: Pick<
    CountryInputProps,
    "className" | "containerClassName"
  >;
  isValidPhoneNumber?: boolean;
  setValidPhoneNumber?: (valid: boolean) => void;

  showPhoneNumberInputInvalidMessage?: boolean;

  required?: string;
}

const PhoneWithCountryField = ({
  phoneName,
  countryName,
  label,
  className,
  placeholder,
  phoneInputProps,
  countryInputProps,
  showPhoneNumberInputInvalidMessage = false,
  required = "Phone is required",
  isValidPhoneNumber,
  setValidPhoneNumber,
  ...props
}: PhoneWithCountryFieldProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <div {...props} className={cn("flex flex-col gap-1", className)}>
      {label ? <p className="mb-[6px] capitalize">{label}</p> : null}
      <div className="flex gap-2">
        <Controller
          name={countryName}
          control={control}
          render={({ field }) => {
            return (
              <CountryInput
                countryCode={field.value}
                {...field}
                {...countryInputProps}
                onChange={(value, dialCode) => {
                  field.onChange(value);
                  setValue("dialCode", dialCode);
                }}
                showArrow
                containerClassName={cn(
                  "max-w-[100px]",
                  "[&_.iti--allow-dropdown]:w-full [&_.iti__country-container]:!w-full",
                  "[&_.select-country-code]:h-full",
                  "[&_.iti--allow-dropdown]:h-full [&_.iti__country-container]:!h-full",
                  "[&_.iti__selected-country]:h-full",
                  "[&_button]:!h-full",
                  "w-full",
                )}
              />
            );
          }}
        />
        <Controller
          name={phoneName}
          control={control}
          rules={{
            required: required,
            pattern: PHONE_NUMBER_REGEX,
            value: phoneNumber,
            onChange: (e) => {
              setPhoneNumber(e.target.value);
              setValidPhoneNumber?.(PHONE_NUMBER_REGEX.test(e.target.value));
            },
          }}
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              {...phoneInputProps}
              placeholder={placeholder}
              isValidPhoneNumber={isValidPhoneNumber}
              showMessage={showPhoneNumberInputInvalidMessage}
            />
          )}
        />
      </div>
      {!isValidPhoneNumber ? (
        <InputErrorMessage message="Please enter a valid phone number" />
      ) : errors.phone ? (
        <InputErrorMessage message={errors.phone.message as string} />
      ) : null}
    </div>
  );
};

export default PhoneWithCountryField;
