import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BoxLayoutProps extends BoxProps {
  children: ReactNode;
  bgColor?: string;
}

const BoxLayout: React.FC<BoxLayoutProps> = ({ children, bgColor = "#FFFBFA", ...props }) => {
  return (
    <Box display="flex" justifyContent="center" {...props} marginBottom={5}>
      <Box
        width="100%"
        maxWidth="1176px"
        p={5}
        borderRadius="30px"
        boxShadow="sm"
        border="1px"
        borderColor="#EAECF0"
        bgColor={bgColor}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BoxLayout;
