import Axios from "axios";
import axios from "../libs/axios";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Cookies from "js-cookie";
import { shutdown } from "@intercom/messenger-js-sdk";
import { User } from "../types/type";
import * as AuthAPI from "../apis/auth";
import {
  COOKIE_KEY,
  COUNTRY_PHONE_CODE,
  LOCAL_STORAGE_KEY,
  ROUTES,
} from "../constants";

interface PhoneCodes {
  [key: string]: number;
}

interface VideoProgress {
  id: string;
  userId: string;
  videoIndex: number;
  progress: number;
  percent: number;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
}

const phoneCodesTyped: PhoneCodes = COUNTRY_PHONE_CODE;

const setIncompleteVideoIndex = (
  progressList: Partial<VideoProgress>[],
  unlockVideo: number,
) => {
  const videoEntries = Object.values(progressList);
  const completedVideoIndices = new Set(
    videoEntries.map((video) => video.videoIndex),
  );

  let videoIndexToUse = unlockVideo !== -1 ? unlockVideo : 6;

  const firstIncompleteVideo = videoEntries.find((video) => !video.completed);

  if (!!firstIncompleteVideo) {
    videoIndexToUse = firstIncompleteVideo?.videoIndex as number;
  } else if (Array.isArray(videoEntries) && videoEntries.length > 0) {
    for (let i = 1; i <= (unlockVideo !== -1 ? unlockVideo : 6); i++) {
      if (!completedVideoIndices.has(i)) {
        videoIndexToUse = i;
        break;
      }
    }
  } else if(Array.isArray(videoEntries) && videoEntries.length === 0) {
    videoIndexToUse = 1;
  }

  const videoIndex = `${videoIndexToUse ? (videoIndexToUse as number) : 0}`;

  localStorage.setItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY, videoIndex);
  Cookies.set(COOKIE_KEY.UNLOCK_VIDEO, videoIndex);
};

type AuthContextValue = {
  user: User | null;
  calendlyWidget: string | null;
  isLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  checkAuth: () => Promise<void>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null);
  const [calendlyWidget, setCalendlyWidget] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const token = Cookies.get(COOKIE_KEY.TOKEN);
    const userId = Cookies.get(COOKIE_KEY.USER_ID);

    try {
      if (token) {
        Axios.defaults.headers.common["Authorization"] = token;
        Axios.defaults.headers.common["x-client-id"] = userId;

        const { data } = await AuthAPI.getMe();
        const user = data?.metadata?.user || {};

        if (user?.roles?.includes("DASHBOARD_ADMIN")) {
          throw new Error("Unauthorized access");
        }

        const { firstName, lastName, email } = user;

        setIncompleteVideoIndex(data?.metadata?.user.watchedVideos, data?.metadata?.user.unlockVideo);
        setUser(data?.metadata?.user);
        setCalendlyWidget(
          `name=${encodeURIComponent(firstName + " " + (lastName || ""))}&email=${encodeURIComponent(email)}`,
        );
      } else {
        Cookies.remove(COOKIE_KEY.TOKEN);
        Cookies.remove(COOKIE_KEY.USER_ID);
        delete axios.defaults.headers.common["Authorization"];
        setUser(null);
        setCalendlyWidget(null);
      }

      setIsLoading(false);
    } catch (e) {
      console.log("Error", e);

      setUser(null);
      setCalendlyWidget(null);
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      AuthAPI.logout();
      shutdown();

      Cookies.remove(COOKIE_KEY.TOKEN);
      Cookies.remove(COOKIE_KEY.USER_ID);
      Cookies.remove(COOKIE_KEY.UNLOCK_VIDEO);
      localStorage.removeItem(LOCAL_STORAGE_KEY.BOOK_CALL_POPUP);
      localStorage.removeItem(LOCAL_STORAGE_KEY.ACTIVE_VIDEO_KEY);

      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["x-client-id"];

      Cookies.remove(
        `intercom-session-${import.meta.env.VITE_INTERCOM_APP_ID}`,
      );

      window.location.href = ROUTES.LOGIN;
    } catch (error) {
      console.log("error", error);
    }
  };

  const contextValue = useMemo(() => {
    return {
      user,
      calendlyWidget,
      isLoading,
      setUser,
      checkAuth,
      logout,
    };
  }, [user, isLoading, calendlyWidget]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};
