import { Box } from "@chakra-ui/react";
import { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { avatars } from "../../constants";
import { useIntersectionElement } from "../../hooks/useIntersectionElement";
import { cn } from "../../utils/utils";
import FiveDaysButton from "../FiveDaysButton";
import GetInstantParticipated from "./parts/GetInstantParticipated";
import GetInstantServices from "./parts/GetInstantServices";
import "./styles.css";

export interface GetInstantAccessProps
  extends ComponentPropsWithoutRef<"section"> {
  title: string;
  videoPlayer: ReactNode;
}

export const getInstantAccessIntersectingEvent = "getInstantAccessIntersecting";

const GetInstantAccess = ({
  title,
  videoPlayer,
  className,
  ...props
}: GetInstantAccessProps) => {
  const [ref, entry] = useIntersectionElement();

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent("getInstantAccessIntersecting", {
        detail: entry?.isIntersecting,
      }),
    );
  }, [entry?.isIntersecting]);

  return (
    <section
      {...props}
      className={cn(
        "relative flex flex-col items-center justify-center pb-[49px] pt-[10px] text-center md:pb-[60px] md:pt-8",
        className,
      )}
    >
      <h1
        className={`md:w-5xl lg:text-42 relative mx-auto mb-[16px] w-full px-6 text-center text-[22px] font-[900] capitalize leading-[35px] text-black xxxs:px-3 xxs:px-6 xs:px-6 ltxs:px-2 ltsm:px-4 sm:w-full md:mb-[18px] md:w-full md:px-16 md:text-[36px] md:leading-[40px] lg:max-w-6xl lg:text-44 lg:leading-[50px] xl:max-w-[1200px] xl:text-44 5xl:max-w-[1200px]`}
      >
        “How anyone, regardless of age, skill level, or experience can create a
        profitable YouTube channel that runs on autopilot in the next 5 days –
        using <em>this</em> simple YouTube loophole in 2024”
      </h1>

      <p className="relative mx-auto mb-[25px] w-[350px] px-0 text-center text-[16px] font-[500] xxxs:w-[300px] md:mb-6 md:w-full md:max-w-5xl md:text-[17px] lg:mb-14 lg:max-w-6xl lg:text-24 xl:mb-14 6xl:mb-24">
        All without showing your face or creating the videos yourself
      </p>

      <Box position="relative" w="full" mb="10">
        {videoPlayer}
      </Box>
      <div>
        <FiveDaysButton ref={ref} className="mb-[13px] md:mb-[21px] md:mt-9" />

        <GetInstantParticipated
          items={avatars}
          className="mb-[28px] justify-center md:mb-10"
        />

        <GetInstantServices />
      </div>

      {/* Background gradient */}
      <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full 6xl:h-screen"></div>
    </section>
  );
};

export default GetInstantAccess;
