import StorytellingParagraph from "../../ui/StoryTelling/StoryTellingParagraph";

export const FirstStoryTellingSection = () => {
  return (
    <>
      <div className="mx-auto mt-12.5 max-w-[778px] px-6 md:mt-16 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              You went from not knowing how to achieve the life of your dreams
              to being able to turn those dreams into reality...
            </span>
          }
        >
          <p>
            To being able to transform your life from living like a prisoner
            stuck in a corporate office...
          </p>
          <p>to having true freedom.</p>
          <p>The kind of freedom that YouTube has allowed me to experience.</p>
          <p>
            The freedom to buy things I’ve always wanted to buy without looking
            at the price tag...
          </p>
          <p>The freedom to take my mom on her dream trip to Switzerland....</p>
          <p>And freedom to live the life I’ve always dreamed of...</p>
          <p>And again, now you have access to this same ability.</p>
          <p className="font-bold">Which means...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[877px] px-6 md:mt-15">
        <StorytellingParagraph
          title={
            <p className="mx-auto max-w-[345px] md:max-w-[unset]">
              It's Now Your Turn To Establish A Successful Faceless Channel That
              Gives You Choice To Do Whatever You Want Whenever You Want
            </p>
          }
        >
          <p>It's your turn to make YouTube into a full-time income...</p>
          <p>And it’s your turn to take back control over your life...</p>
          <p className="font-semibold">
            All you have to do is follow the step-by-step directions and
            implement everything we show you, which took me YEARS to figure out.
          </p>
          <p>That's it.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[700px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph title="That’s The Only Thing Separating You From The Dream...">
          <p>And that is a promise we’re gonna keep.</p>
          <p>
            But we can only keep it if <i>you</i> can keep the promise that you’re
            actually going to put everything you learn into motion—that you’re
            actually going to take action.
          </p>
          <p>
            If you don’t take action, no matter how good the system is - it just
            won't work.
          </p>
          <p>
            That's why, although the decision you just made to sign up <i>is one</i> of
            the most important decisions of your life...
          </p>
          <p>
            It’s actually only the second most important decision of your life.
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[789px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph title="The #1 Most Important Decision Of Your Life Is Going To Be Made, Right Here, Right Now... On This Very Page.">
          <p>
            This one decision is going to mean the difference between you making
            $5,000- $10,000 within a few weeks or a few months...
          </p>
          <p>
            But before I tell you what it is, you wanna make sure you’re paying
            close attention...
          </p>
          <p>
            See ... when I decided to create this program, I decided to do
            something different.
          </p>
          <p>
            It wasn't about making as much money as possible like all these
            gurus do.
          </p>
          <p>
            As you’ve seen, YouTube already pays my bills more than generously.
          </p>
          <p>So this isn’t about money...</p>
          <p>Instead...</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[704px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              My #1 goal is to create as many success stories as possible.
            </span>
          }
        >
          <p>That’s it.</p>
          <p>But achieving that goal is a lot harder than you might think...</p>
          <p>
            Because the only way to do that was to not hold anything back and
            give all my secrets away...
          </p>
          <p>
            And that’s exactly what we did when we created the program for
            you....
          </p>
          <p>
            Every idea, system, and strategy I used to launch my Faceless
            Channels and start making money - you now have access to all of it.
          </p>
          <p>Nothing was held back.</p>
          <p>
            Because that’s the only way I knew that you would succeed with the
            system.
          </p>
          <p>And based on the testimonials and case studies so far...</p>
          <p>I can say we accomplished that.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-[27px] max-w-[816px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph title="Once You Start Going Through And Implementing The System, Everything Is Going To Change For You...">
          <p>
            You’re gonna be able to start up your own faceless channel and get
            it off the ground...
          </p>
          <p>
            Create videos that go viral...and turn them into $5,000-$10,000
            monthly paychecks.
          </p>
          <p>And that’s when everything is going to change for you...</p>
          <p>And your life will go from this to this..</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-[40px] flex max-w-[1076px] flex-col items-center justify-between px-6 lg:flex-row lg:px-0">
        <div className="relative">
          <img
            src="/images/upsell1/jake-with-his-dog.webp"
            alt="jake-with-his-dog"
            className="h-[258px] w-[345px] lg:h-[388px] lg:w-[518px]"
          />
          <div className="absolute right-[33px] top-[158px] hidden translate-x-[100%] lg:inline-block">
            <img
              src="/images/upsell1/jake-arrow.webp"
              alt="arrow-decor"
              className="h-[65px] w-[131px]"
            />
          </div>
          <div className="absolute bottom-[-54px] right-[68px] lg:hidden">
            <img
              src="/images/upsell1/jake-down-arrow.webp"
              className="h-[80px] w-[27px]"
              alt="arrow"
            />
          </div>
        </div>

        <img
          src="/images/upsell1/jake-after-5-years.webp"
          alt="jake-after-5-years"
          className="mt-[30px] h-[431px] w-[345px] lg:mt-0 md:h-[567px] md:w-[454px]"
        />
      </div>
      <p className="mx-auto mt-[30px] flex max-w-[528px] flex-col gap-5 px-6 text-18 font-normal md:mt-10 md:px-0 md:text-26">
        Just like mine did 5 years ago.
      </p>

      <div className="mx-auto mt-12.5 max-w-[816px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              But even though I'm getting insane results using this system right
              now, something was missing in the beginning...
            </span>
          }
        >
          <p>
            See, when I was trying to build my first successful YouTube
            channel...
          </p>
          <p>
            Like many beginners, I thought that getting views required{" "}
            <span className="font-semibold">luck.</span>
          </p>
          <p>
            I thought that you just had to blindly spam as many videos as
            possible, and eventually, you would get lucky.
          </p>
          <p>
            But like many beginners, no matter how many videos I published,{" "}
            <span className="font-semibold">
              no matter how consistent I was,
            </span>{" "}
            I just couldn't get a single video to get any views...
          </p>
          <p>
            I <i>thought</i> I was making good, informative, and entertaining
            videos?...
          </p>
          <p>
            But I just couldn’t get any views no matter how many videos I
            posted,
          </p>
          <p>I couldn't even get a couple thousand views,</p>
          <p>
            And because your income depends on how many views your videos get, I
            definitely wasn’t making any money...
          </p>
          <p>
            So I felt like my channels were flopping and that I wasn’t in
            control of my fate
          </p>
          <p>
            Sponsors didn't want to work with me because my channel was
            “insufficient,” and they were looking for someone who could get more
            views on demand...
          </p>
          <p>
            I made 0 dollars promoting products since no one was watching my
            videos...
          </p>
          <p>
            My new YouTube career started to feel like just a new normal job.
          </p>
          <p>
            The only difference was that I wasn't paid enough this time and I
            hated my boss again - myself.
          </p>
          <p>
            I was trying and trying to get those channels off the ground and
            “hack the algorithm”...
          </p>
          <p>But with 0 success.</p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[820px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              As I kept looking around... I realized that almost every beginner
              channel was struggling with the same thing...
            </span>
          }
        >
          <p>
            Time and time again, I would come across beginner channels that had
            HUNDREDS of videos...
          </p>
          <p>But all their videos would have like 1,000 views...</p>
          <p>Meaning they were barely making any money from it...</p>
          <p>
            These people were breaking their backs putting out all these videos
            and yet they had nothing to show for it.
          </p>
          <p>
            <i>I</i> was breaking my back with nothing to show for it!
          </p>
          <p>
            But then... I also noticed something strange: really big channels
            would go viral all the time.
          </p>
          <p>
            And it’s not like one out of every 10 videos they posted would
            randomly go viral.
          </p>
          <p>
            No, they would go viral <i>every single time</i>, again, again, and
            again...
          </p>
          <p>
            And when they created new channels, those new channels would also go
            viral!
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[729px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              And that was the moment I realized that maybe going viral wasn’t
              just about luck, and I was missing something...
            </span>
          }
        >
          <p>What I’ve come to learn is that going viral is a formula.</p>
          <p>A repeatable process that anyone can follow.</p>
          <p>
            So, from that point on, I stopped treating YouTube as a game of
            luck.
          </p>
          <p>I stopped spamming videos in hopes of going viral,</p>
          <p>
            And I set out to find out exactly{" "}
            <span className="font-semibold">
              why some videos go viral and why others don’t.
            </span>
          </p>
          <p>
            And after analyzing literally <i>thousands</i> of videos over the
            years,
          </p>
          <p>
            And spending over half a million dollars testing out videos to see
            what makes viewers click...
          </p>
          <p>I eventually found out the answer...</p>
          <p>
            <mark>
              I found out that yes, going viral on YouTube is a formula.
            </mark>
          </p>
          <p>
            A formula that works on every video and on every channel, no matter
            what it is about.
          </p>
        </StorytellingParagraph>
      </div>

      <div className="mx-auto mt-12.5 max-w-[652px] px-6 md:mt-15 md:px-0">
        <StorytellingParagraph
          title={
            <span className="capitalize">
              And the <i>minute</i> I changed this one thing...
            </span>
          }
        >
          <p>
            The minute I started using this new process, everything changed.
          </p>
          <p>
            I was able to create successful videos that got tens of thousands,
            if not hundreds of thousands of views on demand.
          </p>
          <p>
            Sponsors who ignored my emails started reaching out to me, begging
            me for a deal!
          </p>
          <p>
            Affiliate products that I promoted were successfully multiplying my
            income,
          </p>
          <p>
            My channels started growing, and the algorithm started recommending
            my videos to new people...
          </p>
          <p>Everything was perfect.</p>
          <p>
            All I was missing that whole time was this one thing - the formula
            to virality.
          </p>
          <p>
            And let me tell you... this formula works better than I could have
            ever hoped.
          </p>
          <p>I've been using it for over five years in a row now.</p>
          <p>
            Anyways, this one problem with views was the same problem my
            students were struggling with in the challenge I hosted in January
            2024 called the Faceless Income Challenge.
          </p>
          <p>
            Even though I shared everything on how to get views with them as I
            did with you...
          </p>
          <p>
            Very few people actually did the work which led them to getting
            results.
          </p>
          <p>Those who did the work were getting results like these:</p>
        </StorytellingParagraph>
      </div>
    </>
  );
};
