import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";

const useUrlParams = (
  ignoreRoutes: string[] = ["/fast-start", "/blueprint"],
) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const storedParams = new URLSearchParams(
      sessionStorage.getItem("persistentParams") || "",
    );

    // Merge stored params with current params
    for (const [key, value] of storedParams.entries()) {
      if (!currentParams.has(key)) {
        currentParams.append(key, value);
      }
    }

    // Update sessionStorage with merged params
    sessionStorage.setItem("persistentParams", currentParams.toString());

    // Check if the current route should be ignored
    const shouldIgnore = ignoreRoutes.some((route) =>
      location.pathname.startsWith(route),
    );

    if (!location.pathname.startsWith(ROUTES.VERIFY)) {
      currentParams.delete("token");
    }

    if (
      location.pathname.startsWith(ROUTES.UPSELL1) ||
      location.pathname.startsWith(ROUTES.UPSELL2)
    ) {
      const params = ["token", "unlockvideo", "userId", "to", "q", "ac"];
      params.forEach((param) => currentParams.delete(param));
    }

    if (shouldIgnore) {
      sessionStorage.removeItem("persistentParams");
    }

    if (
      !shouldIgnore &&
      currentParams.toString() !== location.search.slice(1)
    ) {
      const newUrl = `${location.pathname}?${currentParams.toString()}${location.hash}`;
      navigate(newUrl, { replace: true });
    }
  }, [location, navigate, ignoreRoutes]);
};

export default useUrlParams;
