import NotRightFit from "../components/NotRightFit";

const NotFitPage = () => {
  return (
    <>
      <NotRightFit />
    </>
  );
};

export default NotFitPage;
