import { create } from "zustand";

interface IUseCountdownSore {
  isCountdownExpired: boolean;
  setExpired: (isCountdownExpired: boolean) => void;
}

export const useCountDownStore = create<IUseCountdownSore>((set) => ({
  isCountdownExpired: false,
  setExpired: (isCountdownExpired: boolean) => {
    set({
      isCountdownExpired,
    });
  },
}));
