import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import CheckIcon from "../CheckIcon";
import facebook from "../../assets/images/facebook.webp";
import FacebookIcon from "../FacebookIcon";
import CloseIcon from "../CloseIcon";

type FastStartPopUpProps = {
  isOpen: boolean;
  onClose: () => void;
};

const FastStartPopUp = ({ isOpen, onClose }: FastStartPopUpProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay className="backdrop-blur-sm"/>
      <ModalContent borderRadius="20px"className="min-[768px]:!max-w-[614px] !max-w-[345px] min-[768px]:p-[30px] p-[25px] pt-[30px]">
        <ModalBody p="0" position="relative">
          <Heading
            fontSize="26px"
            lineHeight="32.89px"
            fontWeight="900"
            className="min-[768px]:mb-[45px] mb-[30px] px-[23px]"
            textAlign="center"
          >
            JOIN OUR FACEBOOK GROUP
          </Heading>

          <Box
            onClick={onClose}
            position="absolute"
            right="0"
            className="min-[768px]:!top-[2px] top-[-15px]"
            cursor="pointer"
          >
            <CloseIcon />
          </Box>

          <Flex alignItems="center" className="min-[768px]:flex-row flex-col gap-[30px]">
            <Stack className="min-[768px]:!gap-[19px] !gap-[15px] min-[768px]:spacing-nowrap min-[768px]:mr-auto text-18 bold-450">
              <Box>
                <Flex className="items-center">
                  <CheckIcon />
                  <Text ml="9px">Instant approval</Text>
                </Flex>
              </Box>
              <Box>
                <Flex className="items-center">
                  <CheckIcon />
                  <Text ml="9px">7 & 8-figure business owners</Text>
                </Flex>
              </Box>
              <Box>
                <Flex className="items-center">
                  <CheckIcon />
                  <Text ml="9px">Regular content</Text>
                </Flex>
              </Box>
              <Box>
                <Flex className="items-center">
                  <CheckIcon />
                  <Text ml="9px">Interviews with experts</Text>
                </Flex>
              </Box>
              <Box>
                <Flex className="items-center">
                  <CheckIcon />
                  <Text ml="9px">Like minded people on the same journey</Text>
                </Flex>
              </Box>
            </Stack>

            <Box>
              <Box
                height="155px"
                width="155px"
                borderRadius="14px"
                overflow="hidden"
                position="relative"
              >
                <Image src={facebook} alt="facebook-group" />
                <Box position="absolute" bottom="2px" right="2px">
                  <FacebookIcon />
                </Box>
              </Box>
              <Text
                fontWeight="700"
                mt="14px"
                className="min-[768px]:text-20 text-16 text-center"
              >
                8,423+ Members
              </Text>
            </Box>
          </Flex>

          <Button
            background="primary"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20.24px"
            width="100%"
            color="#fff"
            className="min-[768px]:!mt-[30px] !mt-[20px] min-[768px]:!rounded-[8px] !rounded-[15px] min-[768px]:h-[36px] h-[44px] capitalize min-[768px]:uppercase"
            _hover={{
              background: "primary-dark",
            }}
          >
            Join now
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FastStartPopUp;
