import axios from "axios";
import Cookies from "js-cookie";
import { COOKIE_KEY } from "../constants";

const instance = axios.create({
  baseURL: import.meta.env.PROD
    ? import.meta.env.VITE_SERVER_URL_PROD
    : import.meta.env.VITE_SERVER_URL_DEV_PUBLIC,
  headers: {
    "x-api-key": import.meta.env.VITE_X_API_KEY,
  },
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const accessToken = Cookies.get(COOKIE_KEY.TOKEN);
  const userId = Cookies.get(COOKIE_KEY.USER_ID);

  if (accessToken) {
    config.headers["x-client-id"] = userId;
    config.headers.Authorization = `${accessToken}`;
  }
  return config;
});

export default instance;
