/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentPropsWithoutRef, useEffect } from "react";
import { cn } from "../../utils/utils";
import { Dict } from "../../types/type";

export interface GetInstantAccessVideoProps
  extends ComponentPropsWithoutRef<"div"> {
  id: string;
}

const VidalyticsPlayer = ({ id, className }: GetInstantAccessVideoProps) => {
  useEffect(() => {
    (function (
      v: Dict,
      i: Document,
      d: string,
      a: string,
      l: string,
      y?: string,
      t?: any,
      c?: string,
      s?: any,
    ) {
      y = "_" + d.toLowerCase();
      c = d + "L";
      if (!v[d]) {
        v[d] = {};
      }
      if (!v[c]) {
        v[c] = {};
      }
      if (!v[y]) {
        v[y] = {};
      }
      let vl = "Loader",
        vli = v[y][vl],
        vsl = v[c][vl + "Script"],
        _vlf = v[c][vl + "Loaded"],
        _ve = "Embed";
      if (!vsl) {
        vsl = function (u: string, cb: () => void) {
          if (t) {
            cb();
            return;
          }
          s = i.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = u;
          if (s.readyState) {
            s.onreadystatechange = function () {
              if (s.readyState === "loaded" || s.readyState === "complete") {
                s.onreadystatechange = null;
                _vlf = 1;
                cb();
              }
            };
          } else {
            s.onload = function () {
              _vlf = 1;
              cb();
            };
          }
          i.getElementsByTagName("head")[0].appendChild(s);
        };
      }
      vsl(l + "loader.min.js", function () {
        if (!vli) {
          const vlc = v[c][vl];
          vli = new vlc();
        }
        vli.loadScript(l + "player.min.js", function () {
          const vec = v[d][_ve];
          t = new vec();
          t.run(a);
        });
      });
    })(
      window,
      document,
      "Vidalytics",
      `vidalytics_embed_${id}`,
      `https://fast.vidalytics.com/embeds/vzkDil30/${id}/`,
    );
  }, []);

  return (
    <div
      className={cn(
        "max-w-xl px-6 sm:max-w-md sm:pt-[2rem] md:max-w-3xl md:px-2 md:pt-[4rem] lg:mt-0 lg:max-w-5xl",
        className,
      )}
    >
      <div
        id={`vidalytics_embed_${id}`}
        className="relative aspect-video"
      ></div>
    </div>
  );
};

export default VidalyticsPlayer;
