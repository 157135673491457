import { useEffect } from "react";
import { router } from "./router"; 
import { RouterProvider } from "react-router-dom";
import { initGA } from "./analytics";

const App = () => {
  useEffect(() => {
    initGA(); 
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
