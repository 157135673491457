import { useCallback } from "react";
import { PointerIcon } from "../../assets/icons";
import { useCheckout } from "../../providers/CheckoutProvider";
import { LabelCheckbox } from "../ui/Checkbox";
import Tooltip from "../ui/Tooltip";
import useMagicPrice from "../CountDown/useMagicPrice";

export const MostPopularOption = ({
  getCheckboxProps,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCheckboxProps: any;
}) => {
  const { base, endless } = useMagicPrice();
  const { toggleCheckBoxValue, amount } = useCheckout();

  const handleOnToggle = useCallback(() => {
    toggleCheckBoxValue();
  }, [toggleCheckBoxValue]);

  return (
    <div className="mt-[20px] md:mt-[40px]">
      <div className="flex w-full items-center justify-center gap-5">
        <span className="text-28">🔥</span>
        <div className="inline-block rounded-lg bg-[#D92D20] px-6 py-3 text-16 font-bold uppercase text-white md:px-10 md:text-20">
          MOST POPULAR OPTION
        </div>
        <span className="text-28">🔥</span>
      </div>

      <div className="mt-[21px]">
        <div
          className="relative rounded-[20px] bg-[#FFFBFA] p-[25px]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23D92D20FF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
          }}
        >
          <div className="flex items-start justify-center">
            <div className="relative mt-2 h-3 w-[30px] animate-text-move-bottom-up">
              <img
                src="/images/red-arrow-right.webp"
                alt="red-arrow"
                width="30"
                height="12"
              />
            </div>

            <Tooltip
              label={
                <>
                  <div className="rounded-[4px] bg-[#FFFB19] text-sm font-bold capitalize">
                    74% of our members choose this upgrade
                  </div>
                  <div className="mt-[6px] text-xs">
                    This is only for those want to{" "}
                    <b>INSTANTLY find new & unique video ideas</b> that are
                    proven to go big.{" "}
                    <b>
                      Usually $297, but today only{" "}
                      {`$${endless} ($${297 - endless} savings)`}
                    </b>
                  </div>
                </>
              }
              tooltipLabelClassName="!top-[-15px] !left-[-12px] md:!top-[-10px]"
            >
              <LabelCheckbox
                className="mt-0 flex !items-start"
                {...getCheckboxProps({
                  value: endless,
                })}
              >
                <span className="text-balance text-18 font-black text-[#D92D20] md:text-22">
                  YES UPGRADE MY ORDER WITH “ENDLESS VIDEO IDEAS” SYSTEM ($297
                  VALUE)
                </span>
              </LabelCheckbox>
            </Tooltip>
          </div>

          <p className="mb-[40px] mt-[15px] text-16 font-bold-450 text-[#424242] md:mt-[34px]">
            <span className="font-bold text-black">Save yourself time</span> and{" "}
            <span className="font-bold text-black">
              generate unique video ideas
            </span>{" "}
            for your channel in just{" "}
            <span className="font-bold text-black">10 minutes.</span> To keep
            earning, you need to stay creative and keep posting new videos.
            Jake’s system{" "}
            <span className="font-bold text-black">automates</span> the
            brainstorming process with a proven formula.{" "}
            <span className="font-bold text-black">Over 97% of our users</span>{" "}
            say they wish they’d had this system earlier. Grab instant access
            now for just{" "}
            <span className="font-bold text-black">${endless}</span> (a ${297 - endless} savings)
            . The price goes up to $297 later.{" "}
            <span className="font-bold italic text-black">
              Check the box above to get instant access!
            </span>
          </p>

          <Tooltip
            label={
              <>
                <div className="rounded-[4px] bg-[#FFFB19] text-sm font-bold capitalize">
                  74% of our members choose this upgrade
                </div>
                <div className="mt-[6px] text-xs">
                  This is only for those want to{" "}
                  <b>INSTANTLY find new & unique video ideas</b> that are proven
                  to go big.{" "}
                  <b>
                    Usually $297, but today only{" "}
                    {`$${endless} ($${297 - endless} savings)`}
                  </b>
                </div>
              </>
            }
            tooltipLabelClassName="md:!left-[120px] !top-[-5px] after:left-[40px] md:after:left-[20px] before:left-[40px] md:before:left-[20px]"
            rootMarginBottom="-50%"
          >
            <div
              className="button-shine !absolute bottom-0 left-1/2 top-[-20px] flex h-[93px] w-[314px] -translate-x-2/4 cursor-pointer items-center justify-center gap-4 text-nowrap rounded-xl bg-[#21B209] px-[24px] py-5 text-center text-20 font-black italic text-white shadow-addOrderShadow md:w-[421px] md:px-[42px] md:py-[30px] md:text-26"
              onClick={handleOnToggle}
            >
              <span className="uppercase">
                {![3, base].includes(amount)
                  ? "Added!"
                  : "Yes, Add To My Order"}
              </span>{" "}
              <PointerIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
