/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import NextIcon from "../NextIcon";

import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { signUp } from "../../apis/auth";
import { LOCAL_STORAGE_KEY, ROUTES } from "../../constants";
import { GTAGEvent, gtag_report_conversion } from "../../utils/gtag";
import { handleSendLead, sendIdentification } from "../../utils/utils";
import EmailDomainSuggestion from "../EmailDomainSuggestion";
import ErrorMessage from "../ui/Form/ErrorMessage";
import { EMAIL_REGEX } from "../ui/Input/EmailInput";
import { PreloadImage } from "../ui/Image/PreLoadImage";

const avatars = [
  {
    src: "/images/avatar-1.webp",
    alt: "Avatar 1",
  },
  {
    src: "/images/avatar-2.webp",
    alt: "Avatar 2",
  },
  {
    src: "/images/avatar-3.webp",
    alt: "Avatar 3",
  },
  {
    src: "/images/avatar-4.webp",
    alt: "Avatar 4",
  },
  {
    src: "/images/avatar-5.webp",
    alt: "Avatar 5",
  },
  {
    src: "/images/avatar-6.webp",
    alt: "Avatar 6",
  },
  {
    src: "/images/avatar-7.webp",
    alt: "Avatar 7",
  },
];

type OptInInputs = {
  firstName: string;
  email: string;
};

const OptInSection = () => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isLoading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
    ...methods
  } = useForm<OptInInputs>({
    resolver: zodResolver(
      z.object({
        firstName: z
          .string({ message: "First Name is required" })
          .min(1, { message: "First Name is required" })
          .refine((value) => !!value.trim(), {
            message: "First Name is required",
          }),
        email: z
          .string({ message: "Email is required" })
          .min(1, { message: "Email is required" })
          .refine((value) => !!value.trim(), { message: "Email is required" }),
      }),
    ),
  });

  const [showEmailSuggestions, setShowEmailSuggestions] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const email = watch("email");

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  });

  useEffect(() => {
    if (email && !email.includes("@")) {
      setShowEmailSuggestions(true);
    } else {
      setShowEmailSuggestions(false);
    }
  }, [email]);

  const onSubmit: SubmitHandler<OptInInputs> = async ({ email, firstName }) => {
    if (!EMAIL_REGEX.test(email)) {
      methods.setError("email", {
        message: "Please enter a valid email address",
      });

      return;
    }

    localStorage.setItem(
      LOCAL_STORAGE_KEY.RESULT_PAGE_DATA,
      JSON.stringify({ email, firstName }),
    );

    try {
      setLoading(true);
      await signUp(email, firstName);

      // track quiz opt-in event
      gtag_report_conversion(GTAGEvent.QUIZ_OPT_IN, {});

      handleSendLead(email, firstName);
      sendIdentification(email.toLowerCase());
      const isNotFit = sessionStorage.getItem("vp-isNotFit");
      if (isNotFit) navigate(ROUTES.NOT_FIT);
      else navigate(`${ROUTES.SALES}?utm_term=quizflow`);
    } catch (error: any) {
      console.log(error);
      if (
        error?.response?.data?.message === "Error: User already registered!" &&
        error?.response?.data?.code === 403
      ) {
        navigate(`${ROUTES.SALES}?utm_term=quizflow`);
        return;
      }
      toast({
        title: "Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onEmailSuggestionClick = (email: string) => {
    setValue("email", email);
    setShowEmailSuggestions(false);
  };

  // useEffect(() => {
  //   // Kiểm tra xem heap đã được khởi tạo chưa
  //   if (window.heap && window.heap.track) {
  //     // Gọi hàm identify của Heap
  //     window.heap.track('Test strack', {dollars: 50, item: 'Furby'});
  //     window.heap.identify('testuser@example.com');
  //   }
  // }, []);

  return (
    <>
      <Box
        width={{
          base: "90%",
          md: "max-content",
        }}
        margin="0 auto"
        className="py-10"
      >
        <Heading
          mb={{ base: "45px", md: "50px" }}
          lineHeight={{ base: "37.95px", md: "60.72px" }}
          fontWeight={900}
          textAlign="center"
          fontSize={{ base: "30px", md: "48px" }}
          letterSpacing="-1%"
          fontFamily="CircularStd"
        >
          Done! Where can we send you the results? 👇
        </Heading>
        <Flex
          as="form"
          p={{ base: "20px", md: "40px 36px" }}
          w={{ base: "100%", md: "577px" }}
          margin="0 auto"
          bg="#fff"
          border="1px solid #EAECF0"
          borderRadius="15px"
          flexDirection="column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading
            fontSize={{ base: "16px", md: "22px" }}
            fontWeight="400"
            lineHeight={{
              base: "19.47px",
              md: "26.77px",
            }}
            p="0 16.5px"
            mb={{ base: "21px", md: "40px" }}
            color="#302E2E"
            textAlign="center"
          >
            <Box color="primary" as="span" fontWeight="700">
              Awesome!{" "}
            </Box>
            Please enter your email to receive your results + short video
            explanation.
          </Heading>

          <Stack
            spacing={{
              base: "10px",
              md: "15px",
            }}
            mb={{ base: "25px", md: "28px" }}
            position="relative"
          >
            <Box>
              <Input
                placeholder="First Name"
                borderRadius="12px"
                height={{ base: "38px", md: "42px" }}
                fontSize="14px"
                border="1px solid #EAECF0"
                color="#424242"
                _placeholder={{ color: "#888888", fontWeight: "400" }}
                _focus={{ borderColor: "primary" }}
                _focusVisible={{ boxShadow: "0 0 0 2px #FECDCA" }}
                disabled={isLoading || isSubmitting}
                {...register("firstName")}
              />
              <ErrorMessage name="firstName" control={methods.control} />
            </Box>

            <Box>
              <Input
                placeholder="Email Address"
                type="email"
                borderRadius="12px"
                height={{ base: "38px", md: "42px" }}
                fontSize="14px"
                border="1px solid #EAECF0"
                color="#424242"
                _placeholder={{ color: "#888888", fontWeight: "400" }}
                _focus={{ borderColor: "primary" }}
                _focusVisible={{ boxShadow: "0 0 0 2px #FECDCA" }}
                disabled={isLoading || isSubmitting}
                {...register("email")}
              />
              <ErrorMessage name="email" control={methods.control} />
            </Box>

            {showEmailSuggestions && (
              <EmailDomainSuggestion
                email={email}
                onEmailSuggestionClick={onEmailSuggestionClick}
              />
            )}
          </Stack>

          <Button
            background="primary"
            p="8px 16px"
            h="56px"
            mb="25px"
            textAlign="center"
            borderRadius="8px"
            _hover={{ background: "#a21a10" }}
            _active={{ background: "#a21a10" }}
            type="submit"
            isLoading={isLoading || isSubmitting}
            spinnerPlacement="end"
            loadingText={
              <Text
                fontWeight="700"
                fontSize="20px"
                lineHeight="25.3px"
                color="#fff"
                mr="10px"
              >
                NEXT STEP
              </Text>
            }
            _loading={{
              color: "#fff",
            }}
          >
            <Text
              fontWeight="700"
              fontSize="20px"
              lineHeight="25.3px"
              color="#fff"
              mr="10px"
            >
              NEXT STEP
            </Text>
            <NextIcon className="h-[24px] w-[24px] 2xl:size-[24px]" />
          </Button>

          <Flex m="0 auto" mb="20px">
            {avatars.map((avatar) => (
              <PreloadImage
                src={avatar.src}
                alt={avatar.alt}
                key={avatar.alt}
                h="36px"
                w="36px"
                ml="-12px"
              />
            ))}
            <Flex
              h="36px"
              w="36px"
              borderRadius="50%"
              border="1px solid #CFCFCF"
              justifyContent="center"
              alignItems="center"
              bg="#fff"
              ml="-12px"
            >
              <Text
                color="primary"
                fontWeight="450"
                fontSize="12px"
                lineHeight="15.18px"
              >
                1.5k+
              </Text>
            </Flex>
          </Flex>

          <Text
            fontSize={{
              base: "12px",
              md: "14px",
            }}
            fontWeight="500"
            lineHeight={{ base: "14.6px", md: "17.04px" }}
            textAlign="center"
            color="#888888"
          >
            Your email is safe and we don’t share it with anyone. (pinky
            promise)
          </Text>
        </Flex>
      </Box>
        <PreloadImage
          src={isMobile ? "/images/optin-4.webp" : "/images/optin-3.webp"}
          position="absolute"
          bottom="0"
          left="0"
          zIndex="-1"
          w="100%"
          h="100%"
        />
    </>
  );
};

export default OptInSection;
