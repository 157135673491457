import { useEffect, useMemo, useRef } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useAuth } from "../../providers/AuthProvider";

const CalendlyWidget = () => {
  const isMobile = useIsMobile();
  const { calendlyWidget } = useAuth();
  const calendlyWidgetUserInfo = useMemo(
    () => (!!calendlyWidget ? calendlyWidget : ""),
    [calendlyWidget],
  );

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isMobile]);

  const widgetContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (widgetContainerRef.current && calendlyWidgetUserInfo) {
      const iframe = widgetContainerRef.current.querySelector("iframe");

      if (iframe) {
        iframe.src = `https://calendly.com/d/5kr-zn7-dq3/channel-introductory-session?${isMobile ? "hide_event_type_details=1" : ""}&hide_gdpr_banner=1&primary_color=d92c20&${calendlyWidgetUserInfo}`;
      }
    }
  }, [calendlyWidgetUserInfo]);

  if (isMobile) {
    return (
      <div
        ref={widgetContainerRef}
        className="calendly-inline-widget h-[700px] min-w-[320px]"
        data-url={`https://calendly.com/d/5kr-zn7-dq3/channel-introductory-session?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=d92c20&${calendlyWidgetUserInfo}`}
      ></div>
    );
  }

  return (
    <div
      className="calendly-inline-widget h-[700px] min-w-[320px]"
      ref={widgetContainerRef}
      data-url={`https://calendly.com/d/5kr-zn7-dq3/channel-introductory-session?hide_gdpr_banner=1&primary_color=d92c20&${calendlyWidgetUserInfo}`}
    ></div>
  );
};
export default CalendlyWidget;
