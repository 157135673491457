import {
  Box,
  Flex,
  Grid,
  HTMLChakraProps,
  Image,
  Text,
} from "@chakra-ui/react";
import { LockIcon } from "../../assets/icons/LockIcon";
import { VideoType } from "../../pages/membership";
import PlayIcon from "../PlayIcon";
import { SlidingDiv } from "../ui/Layout/SlidingDiv";
import { getUnlockTitleByIndex, UnlockTitle } from "./UnlockTitle";

type FastStartVideosProps = {
  videos: VideoType[];
  unlockVideo: number;
  activeVideoIndex: number;
  setActiveVideoIndex: (value: number) => void;
};

const FastStartVideos = ({
  videos,
  unlockVideo,
  activeVideoIndex,
  setActiveVideoIndex,
}: FastStartVideosProps) => {

  const getCardStyles = (
    index: number,
    activeVideo: number,
  ): HTMLChakraProps<"div"> => {
    if (index === activeVideo) {
      return { boxShadow: "2px 2px #d92d20" };
    }

    return { border: "1px solid #eaecf0" };
  };

  const getOverlayBackground = (
    availableVideo: boolean,
    isLastVideo: boolean,
  ) => {
    if (availableVideo) {
      return "rgba(0, 0, 0, 0.2)";
    } else if (isLastVideo) {
      return "rgba(1, 1, 1, 0.5)";
    }

    return "rgba(0, 0, 0, 0.2)";
  };

  const handleOnClickVideoThumbnail = (index: number) => {
    if (index < unlockVideo) {
      setActiveVideoIndex(index);
    }
  };

  return (
    <Box position="relative" mr={{ base: -6, md: 0 }}>
      <Box
        className="bg-gradient-to-l from-white to-slate-400 blur-xl md:hidden"
        position="absolute"
        top="0"
        left="-24px"
        height="100%"
        width="28px"
        zIndex="100"
      />
      <Grid
        background={{
          base: "#F9FAFB",
          md: "#fff",
        }}
        borderRadius={{
          base: "0",
          md: "18px",
        }}
        border={{
          base: "none",
          md: "1px solid #eaecf0",
        }}
        gridTemplateColumns="repeat(6, 1fr)"
        overflowX={{ base: "scroll", md: "auto" }}
        p={{
          md: "20px",
          base: 0,
        }}
        mb="30px"
        gap={{
          base: "8px",
        }}
      >
        {videos.map((video, index) => {
          const availableVideo = index < unlockVideo;
          const lastVideo = videos.length - 1;
          const isLastVideo = index === lastVideo;

          return (
            <Flex
              flexDirection="column"
              textAlign="center"
              width="160px"
              key={video.title}
            >
              <Box
                key={video.title}
                height="100px"
                width="160px"
                borderRadius="12px"
                overflow="hidden"
                position="relative"
                mb="12px"
                cursor="pointer"
                {...getCardStyles(index, activeVideoIndex)}
                onClick={() => handleOnClickVideoThumbnail(index)}
              >
                <Image
                  src={video.imageUrl}
                  alt={video.title}
                  style={{
                    height: "100px",
                  }}
                  objectFit={"cover"}
                />

                <Box
                  width="100%"
                  height="100%"
                  top="0"
                  left="0"
                  position="absolute"
                  background={getOverlayBackground(availableVideo, isLastVideo)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {availableVideo ? (
                    <PlayIcon />
                  ) : (
                    <SlidingDiv
                      bottom={
                        <UnlockTitle
                          {...getUnlockTitleByIndex(index - unlockVideo + 1)}
                        />
                      }
                      showing={<LockIcon />}
                    />
                  )}

                  <Flex
                    position="absolute"
                    left="0"
                    bottom="0"
                    height="25px"
                    minWidth="64px"
                    background={isLastVideo ? "#18CE356B" : "#0000006B"}
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="0 11px 0 11px"
                    backdropFilter="blur(2px)"
                  >
                    <Text
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="17.31px"
                      color="#fff"
                    >
                      {isLastVideo ? "Bonus" : `Day ${index + 1}`}
                    </Text>
                  </Flex>
                </Box>
              </Box>
              <Text
                fontWeight="500"
                className="text-14 max-[912px]:text-left md:text-16"
              >
                {video.title}
              </Text>
            </Flex>
          );
        })}
      </Grid>
      <Box
        className="bg-gradient-to-r from-white to-slate-400 blur-xl md:hidden"
        position="absolute"
        top="0"
        right="0"
        height="100%"
        width="14px"
        zIndex="100"
      />
    </Box>
  );
};

export default FastStartVideos;
