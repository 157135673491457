import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { avatars, ROUTES } from "../../constants";
import { cn } from "../../utils/utils";
import NextIcon from "../NextIcon";
import SaleOff from "../SalesButton/SaleOff";
import { getInstantAccessIntersectingEvent } from "./GetInstantAccess";
import GetInstantParticipated from "./parts/GetInstantParticipated";

export interface WannaInstantAccessProps
  extends ComponentPropsWithoutRef<"div"> {}

const WannaInstantAccess = ({ ...props }: WannaInstantAccessProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const [scrollTo, setScrollTo] = useState("everythingyouget");

  // Function to update scrollTo based on the position of the element
  const handleScroll = () => {
    const firstElement = document.getElementById("everythingyouget");
    const secondElement = document.getElementById("checkoutsteps");
    if (firstElement && secondElement) {
      const firstElementPosition =
        firstElement.getBoundingClientRect().top + window.scrollY;
      const secondElementPosition =
        secondElement.getBoundingClientRect().top + window.scrollY;
      const currentPosition = window.scrollY;

      if (currentPosition > firstElementPosition) {
        setScrollTo("checkoutsteps");
      } else if (currentPosition > secondElementPosition) {
        setScrollTo("everythingyouget");
      } else {
        setScrollTo("everythingyouget");
      }
    }
  };

  // Function to trigger the spotform submit button
  const triggerClick = () => {
    const firstElement = document.getElementById("spotformsubmit");
    if (firstElement) {
      const firstElementPosition =
        firstElement.getBoundingClientRect().top + window.scrollY;
      const currentPosition = window.scrollY + window.innerHeight;
      if (currentPosition < firstElementPosition) {
        return;
      }
    }

    const targetButton = document.getElementById(
      "spotformsubmit",
    ) as HTMLElement | null;
    if (targetButton) {
      setScrollTo("checkoutsteps");
      targetButton.click();
    }
  };

  useEffect(() => {
    document.addEventListener(getInstantAccessIntersectingEvent, (ev) => {
      if (ev instanceof CustomEvent) {
        setIsSticky(!ev.detail);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HashLink smooth to={`${ROUTES.SALES}#${scrollTo}`} onClick={triggerClick}>
      <div
        {...props}
        className={cn(
          "flex max-h-[95px] w-full flex-col items-center justify-center",
          "z-50 bg-primary px-[15px] py-5 text-center md:hidden",
          "fixed bottom-0 left-0 whitespace-nowrap md:static md:bottom-auto md:left-auto",
          "transform transition-all duration-300",
          isSticky ? "translate-y-0" : "translate-y-[100%]",
        )}
      >
        <h2
          style={{ textShadow: "1px 1px 1px rgba(0,0,0,.15)" }}
          className="flex items-center justify-center gap-2 text-20 font-black uppercase text-white"
        >
          Yes, I Want Instant Access
          <NextIcon className="h-5 w-5" />
        </h2>

        <div className="mt-2 flex translate-x-[4px] transform items-center gap-2">
          <GetInstantParticipated
            variant="sm"
            items={avatars}
            className="justify-center"
          />
          <SaleOff className="translate-x-3 transform !text-12 text-white md:mt-4" />
        </div>
      </div>
    </HashLink>
  );
};

export default WannaInstantAccess;
