import { Image, ImageProps } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

type PreloadImageProps = {
  src: string;
} & ImageProps;

export const PreloadImage = ({ src, ...attr }: PreloadImageProps) => {
  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={src} />;
      </Helmet>
      <Image src={src} {...attr} />
    </>
  );
};
