import { shutdown } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import {
  Navigate,
  ScrollRestoration,
  useLocation,
  useRoutes,
} from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import PrivateRoute from "../components/PrivateRoute";
import { ROUTES } from "../constants";
import { initIntercom } from "../libs/intercom";
import { useAuth } from "../providers/AuthProvider";
import { CheckoutProvider } from "../providers/CheckoutProvider";
import BluePrint from "./blue-print";
import CheckoutPage from "./checkout";
import DiscoveryBookedPage from "./discovery-booked";
import DownSellPage from "./downsell";
import HomePage from "./home";
import InterViewPage from "./interview";
import LoginPage from "./login";
import MemberShipPage from "./membership";
import MustWatchPage from "./must-watch";
import NotFitPage from "./not-fit";
import { NotFoundPage } from "./not-found";
import OptInPage from "./opt-in";
import PrivacyPolicyPage from "./privacy";
import QualifyPage from "./qualify";
import QuizPage from "./quiz";
import SalesPage from "./sales";
import YtPassionPage from "./yt-passion";
import SeeIfYouQualifyPage from "./see-if-you-qualify";
import TermsAndServicePage from "./terms";
import UpSellPage from "./upsell";
import UpSell2Page from "./upsell2";
import VerifyPage from "./verify";
import WailListPage from "./waitlist";
import UrlParamsPersistence from "../components/UrlParamsPersistence";
import { OtosuPage } from "./otosu";
import { DmMePage } from "./dm-me";
import PassionPage from "./Passion";
import usePageTracking from "../hooks/usePageTracking";
import FiveDaysPage from "./five-days";
import ConfrimCallDone from "./confirm-call-done";
import CheckoutPageSecond from "./checkoutSecond";
import ProfitCalculator from "./profit-calculator";
import Footer from "../components/shared/Footer";

/**
 *
 * By default, in a React Router application, after a page transition, the scroll doesn't get reset meaning if the user had scrolled somewhere on the page before the page transition, then after the page transition, the page remains scrolled at the same position.
 * This RootPage component sole purpose is to reset scroll to the top of the page when a React Router app transitions to a new page.
 */
const RootPage = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const {
    PASSION,
    FIVE_DAYS,
    YT_PASSION,
    SALES,
    ACCESS,
    QUIZ,
    CHECKOUT,
    CHECKOUT2,
    OPTIN,
    LOGIN,
    UPSELL1,
    UPSELL2,
    DOWNSELL,
    MEMBERSHIP,
    BLUEPRINT,
    VERIFY,
    PRIVACY,
    TERMS,
    NOT_FIT,
    HOME,
    QUALIFY,
    INTERVIEW,
    MUST_WATCH,
    CONFIRM_CALL_DONE,
    WAITLIST,
    DISCOVERY_BOOKED,
    SEE_IF_YOU_QUALIFY,
    OTOSU,
    DM_ME,
    PROFIT_CALCULATOR
  } = ROUTES;

  const ignoredIntercomRoutes = [
    PASSION,
    FIVE_DAYS,
    SALES,
    QUIZ,
    UPSELL1,
    UPSELL2,
    DOWNSELL,
    OPTIN,
    OTOSU,
    DM_ME,
  ];

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    /**
     * Checkout page - Delay 60s
     * Other pages - No delay
     * Sales and Quiz pages - No Intercom
     */
    if (pathname === CHECKOUT) {
      timeoutId = setTimeout(() => {
        initIntercom(user);
      }, 60000);
      return;
    }

    if (pathname === CHECKOUT2) {
      timeoutId = setTimeout(() => {
        initIntercom(user);
      }, 60000);
      return;
    }

    if (!ignoredIntercomRoutes.includes(pathname)) {
      initIntercom(user);
    } else {
      shutdown();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user, pathname]);

  const routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: QUIZ, element: <QuizPage /> },
    { path: OPTIN, element: <OptInPage /> },
    { path: LOGIN, element: <LoginPage /> },
    {
      path: CHECKOUT2,
      element: (
        <CheckoutProvider>
          <CheckoutPage />
        </CheckoutProvider>
      ),
    },
    {
      path: CHECKOUT,
      element: (
        <CheckoutProvider>
          <CheckoutPageSecond />
        </CheckoutProvider>
      ),
    },
    { path: SALES, element: <SalesPage /> },
    { path: YT_PASSION, element: <YtPassionPage /> },
    { path: PASSION, element: <PassionPage /> },
    { path: FIVE_DAYS, element: <FiveDaysPage /> },
    {
      path: ACCESS,
      element: <Navigate to="/your-results?el=sms-abandoned-cart" />,
    },
    { path: VERIFY, element: <VerifyPage /> },
    { path: PRIVACY, element: <PrivacyPolicyPage /> },
    { path: TERMS, element: <TermsAndServicePage /> },
    {
      path: MEMBERSHIP,
      element: <PrivateRoute element={<MemberShipPage />} />,
    },
    {
      path: BLUEPRINT,
      element: <PrivateRoute element={<BluePrint />} />,
    },
    {
      path: PROFIT_CALCULATOR,
      element: <ProfitCalculator />,
    },
    {
      path: UPSELL1,
      element: <UpSellPage />,
    },
    // {
    //   path: UPSELL2,
    //   element: <UpSell2Page />,
    // },
    {
      path: DOWNSELL,
      element: <DownSellPage />,
    },
    { path: NOT_FIT, element: <NotFitPage /> },
    { path: HOME, element: <HomePage /> },
    { path: QUALIFY, element: <QualifyPage /> },
    { path: DISCOVERY_BOOKED, element: <DiscoveryBookedPage /> },
    { path: SEE_IF_YOU_QUALIFY, element: <SeeIfYouQualifyPage /> },
    { path: INTERVIEW, element: <InterViewPage /> },
    { path: MUST_WATCH, element: <MustWatchPage /> },
    { path: CONFIRM_CALL_DONE, element: <ConfrimCallDone /> },
    { path: WAITLIST, element: <WailListPage /> },
    { path: OTOSU, element: <OtosuPage /> },
    { path: DM_ME, element: <DmMePage /> },
    { path: "*", element: <NotFoundPage /> },
  ]);

  usePageTracking();

  return (
    <>
      <ScrollRestoration />
      <FullScreenLoader />
      <UrlParamsPersistence>{routes}</UrlParamsPersistence>
      <Footer />
    </>
  );
};

export default RootPage;
