import { Box, Text, Link } from "@chakra-ui/react";
import { RedButton } from "../components/ui";
import SpiralArrowImage from "../assets/images/sales_arrow.webp";
import ArrowDownImage from "../assets/images/arrow-down.webp";
import { DoubleArrowRight } from "../assets/icons";
import { PreloadImage } from "../components/ui/Image/PreLoadImage";

export const DmMePage = () => {
  return (
    <div className="flex h-dvh w-screen flex-col items-center justify-center">
      <div className="relative flex w-screen justify-center px-[23px]">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-[22px] flex gap-3 text-20 md:px-[10px] md:py-[18px] md:text-24">
            <span>⚠️</span>
            <Text className="text-center text-20 font-medium md:text-24">
              Your form is not yet submitted
            </Text>
            <span>⚠️</span>
          </div>
          <div className="relative mb-9">
            <Text className="max-w-[347px] text-pretty text-30 font-black md:max-w-[680px] md:text-32 text-center">
              Click the button below and DM me the word “DONE” on Instagram, so
              I can message you
            </Text>
            <Box className="absolute bottom-0 right-0 -z-10 translate-x-1/2 translate-y-1/2 transform md:top-0 md:translate-x-full md:translate-y-2/3">
              <PreloadImage
                className="hidden md:inline"
                src={SpiralArrowImage}
                alt="viral profit arrow"
              />
              <PreloadImage
                className="hidden min-[375px]:block md:hidden"
                src={ArrowDownImage}
                alt="viral profit arrow"
                width="21px"
              />
            </Box>
          </div>
          <Link
            variant="plain"
            href="https://urlgeni.us/instagram/RN6c"
            style={{ textDecoration: "none" }}
          >
            <RedButton className="max-w-[347px] text-18 font-black uppercase shadow-btnInsetShadow md:max-w-[647px] md:py-[30px] md:text-24">
              Click here and DM me the word “DONE” <DoubleArrowRight />
            </RedButton>
          </Link>
        </div>
        <Box
          className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2 transform"
          sx={{
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(90deg, #4F2CA1 0%, #7C72D2 18.05%, #A86C9E 38.5%, #DB8759 54.29%, #A85C56 71.17%, #743376 87.19%, #400574 100%)",
            opacity: 0.25,
            filter: "blur(100px)",
          }}
        />
      </div>
    </div>
  );
};
