import { Flex, Image, Text } from "@chakra-ui/react";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../../utils/utils";

export interface GetInstantParticipatedItem {
  src?: string;
  alt?: string;
}

export interface GetInstantParticipatedProps
  extends ComponentPropsWithoutRef<"div"> {
  items: GetInstantParticipatedItem[];
  variant?: "sm" | "base" | "large";
  hideCountUser?: boolean;
}

const GetInstantParticipated = ({
  items,
  className,
  variant = "base",
  hideCountUser = false,
  ...props
}: GetInstantParticipatedProps) => {
  return (
    <div {...props} className={cn("flex", className)}>
      {items.map((avatar) => (
        <Image
          src={avatar.src}
          alt={avatar.alt}
          key={avatar.alt}
          className={cn(
            "rounded lg:h-[38px] lg:w-[38px]",
            {
              sm: "ml-[-8px] h-[24px] w-[24px] translate-x-[12px] transform",
              base: "-ml-[11px] h-[38px] w-[38px]",
              large:
                "ml-[-8px] h-[24px] w-[24px] lg:-ml-[15px] lg:h-[42px] lg:w-[42px]",
            }[variant],
          )}
        />
      ))}

      {!hideCountUser && (
        <Flex
          className={cn(
            "lg:h-[38px] lg:w-[38px]",
            {
              sm: "ml-[-8px] h-[24px] w-[24px] shrink-0 translate-x-[12px] transform",
              base: "ml-[-11px] h-[38px] w-[38px]",
              large:
                "ml-[-8px] h-[24px] w-[24px] lg:-ml-[15px] lg:h-[42px] lg:w-[42px]",
            }[variant],
          )}
          borderRadius="50%"
          border="1px solid #CFCFCF"
          justifyContent="center"
          alignItems="center"
          bg="#fff"
        >
          <Text
            color="primary"
            fontWeight="450"
            className={cn(
              {
                sm: "text-[8px]",
                base: "text-[12px] md:text-[12px]",
                large:
                  "ml-[-8px] h-[24px] w-[24px] lg:-ml-[15px] lg:h-[42px] lg:w-[42px]",
              }[variant],
            )}
          >
            1.5k+
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default GetInstantParticipated;
