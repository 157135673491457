import JakePaul from "../../assets/images/jakepaul.webp";
import JakePaulAvatar from "../../assets/images/jakepaula.webp";
import MrBeast from "../../assets/images/mrbeast.webp";
import MrBeastAvatar from "../../assets/images/mrbeasta.webp";
import Ryan from "../../assets/images/ryan.webp";
import RyanAvatar from "../../assets/images/ryans.webp";

import JakeTranAvatar from "../../assets/images/jakeyt.webp";
import JakeTran from "../../assets/images/yt2.webp";

import Yt1 from "../../assets/images/yt1.webp";
import Yt1Avatar from "../../assets/images/yt1_avatar.webp";

import { ComponentPropsWithoutRef } from "react";
import Yt2Avatar from "../../assets/images/yt2_avatar.webp";
import Yt2 from "../../assets/images/yt3.webp";
import { checkIOS, cn } from "../../utils/utils";
import { CountUpAnimation } from "../CountUpAnimation";

import CountingUpEvilFoodSupply from "../../assets/videos/counting_up_Evil_Food_Supply.mp4";
import CountingUpHTGAWI from "../../assets/videos/counting_up_HTGAWI.mp4";
import CountingUpJakePaul from "../../assets/videos/counting_up_Jake_Paul.mp4";
import CountingUpJakeTran from "../../assets/videos/counting_up_Jake_Tran.mp4";
import CountingUpMrBest from "../../assets/videos/counting_up_Mr_Beast.mp4";
import CountingUpRyan from "../../assets/videos/counting_up_Ryan.mp4";
import { PreloadImage } from "../ui/Image/PreLoadImage";

export interface CardProps {
  name: string;
  backgroundUrl: string;
  avatarUrl: string;
  value: string;
  animation: string;
}

export const Card = ({
  name,
  backgroundUrl,
  avatarUrl,
  animation,
  value,
}: CardProps) => {
  const isIOS = checkIOS();
 
  return (
    <div className="flex flex-col items-center justify-center text-black">
      <h2 className="mb-4 text-20 font-bold md:mb-[18px] md:text-26">{name}</h2>

      <div
        className={cn(
          "relative flex h-[267px] w-full max-w-[345px] flex-col items-center justify-between rounded-[18px]",
          "border-[1px] border-card-border bg-card-bg lg:h-[267px] lg:max-w-[292px]",
          "overflow-hidden",
        )}
      >
        <PreloadImage
          src={backgroundUrl}
          alt="background"
          loading="lazy"
          className="h-[124px] w-full min-w-[300px] max-w-[343px] bg-black/10 lg:min-w-[290px] lg:max-w-[290px]"
        />
        <PreloadImage
          src={avatarUrl}
          alt="Avatar"
          loading="lazy"
          className="absolute top-[4.5rem] z-40 flex h-[108px] w-[108px] justify-center"
        />

        <div className="w-full max-w-[250px] pb-[15px] md:max-w-[247px] md:px-0">
          <div
            style={{
              boxShadow: "0px 24px 42px 0px rgba(0, 0, 0, 0.22)",
              border: "4px solid #ffffff",
              borderImageSource:
                "linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 100%) ",
            }}
            className="flex h-[58px] w-full items-center justify-center overflow-hidden rounded-[18px] bg-black"
          >
            {
              isIOS ? (
                <CountUpAnimation duration={1200} number={value} symbol="$" />
              ) :  <video
              className="h-[95%] w-[95%] overflow-hidden"
              playsInline
              webkit-playsinline="true"
              autoPlay
              muted
            >
              <source src={animation} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export interface Card2Props extends ComponentPropsWithoutRef<"div"> {
  backgroundUrl: string;
  avatarUrl: string;
  active?: boolean;
  value: string;
  animation: string;
}

export const Card2 = ({
  backgroundUrl,
  avatarUrl,
  animation,
  active,
  value,
  className,
  ...props
}: Card2Props) => {
  const isIOS = checkIOS();

  return (
    <div
      {...props}
      className={cn(
        "flex flex-1 flex-col items-center justify-center",
        className,
      )}
    >
      <div
        className={cn(
          "border-card-borer relative flex h-[267px] w-full max-w-[345px] flex-col items-center justify-between",
          "rounded-[18px] border-[1px] bg-card-bg lg:h-[267px] lg:max-w-[292px]",
          active ? "bg-card-bg" : "",
        )}
      >
        <div className="h-[124px] w-full min-w-[300px] max-w-[343px] lg:min-w-[290px] lg:max-w-[290px]">
          <PreloadImage src={backgroundUrl} alt="Background Image" loading="lazy" />
        </div>

        <div className="absolute top-[2.8rem] z-40 flex h-[108px] w-[108px] justify-center">
          <PreloadImage src={avatarUrl} alt="Avatar" loading="lazy" />
        </div>

        <div className="mb-[14px] flex w-[246px] items-end justify-center lg:mb-4">
          <div
            className="card-linear w-full rounded-[18px] p-1"
          // style={{
          //   boxShadow: "0px 24px 42px 0px rgba(0, 0, 0, 0.22)",
          //   border: "4px solid #FFFFFF",
          //   borderImageSource:
          //     " linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 100%) ",
          // }}
          >
            <div className="flex max-h-[76px] w-full max-w-full shrink-0 flex-col items-center justify-center overflow-hidden rounded-[18px] bg-black px-[35px] py-[10px] lg:w-[246px]">
               {
                  isIOS ? (
                    <>
                      <CountUpAnimation duration={1200} number={value} />
                      <p className="text-16 mt-1 text-[#FDA29B]">Subscribers</p>
                    </>
                  ) :  <video
                  className="h-[95%] w-[95%] overflow-hidden"
                  playsInline
                  webkit-playsinline="true"
                  autoPlay
                  muted
                >
                  <source src={animation} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface SalesCardProps extends ComponentPropsWithoutRef<"div"> {
  variant?: "subscriber";
}

const SalesCard = ({ variant, className, ...props }: SalesCardProps) => {
  if (variant === "subscriber") {
    return (
      <div
        {...props}
        className={cn(
          "flex flex-col gap-x-6 gap-y-[19px] lg:flex-row",
          className,
        )}
      >
        <Card
          name="Mr. Beast"
          backgroundUrl={MrBeast}
          avatarUrl={MrBeastAvatar}
          animation={CountingUpMrBest}
          value="50000000"
        />
        <Card
          name="Dude Perfect"
          backgroundUrl={JakePaul}
          avatarUrl={JakePaulAvatar}
          animation={CountingUpJakePaul}
          value="20000000"
        />
        <Card
          name="Ryan's World"
          backgroundUrl={Ryan}
          avatarUrl={RyanAvatar}
          animation={CountingUpRyan}
          value="32000000"
        />
      </div>
    );
  }

  return (
    <div
      {...props}
      className={cn(
        "flex flex-col gap-x-6 gap-y-[21px] lg:flex-row",
        className,
      )}
    >
      <div className="order-2 lg:order-1 lg:mt-6">
        <Card2
          backgroundUrl={Yt1}
          avatarUrl={Yt1Avatar}
          animation={CountingUpEvilFoodSupply}
          value="274000"
        />
      </div>
      <div className="order-1 lg:order-2">
        <Card2
          backgroundUrl={JakeTran}
          avatarUrl={JakeTranAvatar}
          animation={CountingUpJakeTran}
          value="1820000"
          className="[&>div]:shadow-[0px_24px_48px_0px_#0000001A]"
          active
        />
      </div>
      <div className="order-3 lg:order-3 lg:mt-6">
        <Card2
          backgroundUrl={Yt2}
          avatarUrl={Yt2Avatar}
          animation={CountingUpHTGAWI}
          value="18100"
        />
      </div>
    </div>
  );
};

export default SalesCard;
