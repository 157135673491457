import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { COOKIE_KEY, LOCAL_STORAGE_KEY } from "../../constants";
import { decodeValue } from "../../libs/common";
import { generateMagicKey } from "../../utils/utils";

const useMagicPrice = () => {
  // const [chanceArray, setChanceArray] = useState<number[]>([7, 39, 27]);
  const [chanceArray, setChanceArray] = useState<number[]>([27, 99, 47]);

  useEffect(() => {
    const storedEncodedMagicKey = localStorage.getItem(
      LOCAL_STORAGE_KEY.MAGIC_PRICE,
    );

    const isFillQuiz = Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)
    ?  decodeValue(Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)!)
    : "";

    const salesExpire = Cookies.get(COOKIE_KEY.SALES_EXPIRE)
    ?  decodeValue(Cookies.get(COOKIE_KEY.SALES_EXPIRE)!)
    : "";

    if (!storedEncodedMagicKey) {
      const newMagicKey = generateMagicKey(isFillQuiz === "0" || salesExpire === "0");

      localStorage.setItem(LOCAL_STORAGE_KEY.MAGIC_PRICE, newMagicKey);
      setChanceArray(getPrice(newMagicKey))
    } else {
      const shuffledKey = storedEncodedMagicKey
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
      setChanceArray(getPrice(shuffledKey))
    }
  }, []);

  return { base: chanceArray[0], original: chanceArray[1], endless: chanceArray[2]  };
};

const getPrice = (shuffledKey: string) => {
  if (shuffledKey.includes("A") && shuffledKey.includes("a")) {
    // return [7, 39, 27]; // base, original, endless
    return [27, 99, 47]; // base, original, endless
  }

  // return [27, 99, 95]; // base, original, endless
  return [27, 99, 47]; // base, original, endless
};

export default useMagicPrice;
