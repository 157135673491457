import { LOCAL_STORAGE_KEY } from "../constants";
import { Dict } from "../types/type";

/**
 *
 * @param data {Dict}
 * Handle the update the saleUserData object in the local storage
 */
export const updateSaleUserDataLocalStorage = (data: Dict) => {
  const saleUserData = localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA);

  if (saleUserData) {
    const saleUserDataObj = JSON.parse(saleUserData);

    Object.keys(data).forEach((key) => {
      saleUserDataObj[key] = data[key];
    });

    localStorage.setItem(
      LOCAL_STORAGE_KEY.SALE_USER_DATA,
      JSON.stringify(saleUserDataObj),
    );
  } else {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.SALE_USER_DATA,
      JSON.stringify(data),
    );
  }
};
