import { useEffect } from "react";
import { PointerIcon } from "../../assets/icons";
import { useCheckout } from "../../providers/CheckoutProvider";
import { formatCurrency } from "../../utils/money";
import ThreeDotsLoading from "../ThreeDotsLoading";
// import { PaymentDecline } from "./PaymentDecline";
import { useMediaQuery } from "@chakra-ui/react";
import clsx from "clsx";
import useMagicPrice from "../CountDown/useMagicPrice";
import Tooltip from "../ui/Tooltip";
import { debounce } from "lodash";

export const SummaryPrice = () => {
  const { handleCreditCard, amount, isProcessing } = useCheckout();
  const { base, original } = useMagicPrice();

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const debouncedHandleCreditCard = debounce(handleCreditCard, 300);

  useEffect(() => {
    if (isProcessing && isMobile) {
      window.document.body.classList.add("!overflow-hidden");
    } else {
      window.document.body.classList.remove("!overflow-hidden");
    }

    return () => {
      window.document.body.classList.remove("!overflow-hidden");
    };
  }, [isProcessing, isMobile]);

  return (
    <div className="mt-[93px] xxxs:px-3 lg:px-0">
      <div className="flex w-full justify-between border-b border-b-[#E7E7E7] py-[10px] text-22 font-bold text-[#F24035]">
        <div>Normal Price:</div>
        <div className="line-through">
          {[3, base].includes(amount) ? `$${original}` : `$${297 + original}`}{" "}
        </div>
      </div>
      <div className="flex w-full justify-between border-b border-b-[#E7E7E7] py-[10px] text-18 font-bold text-[#212529]">
        <div>Your Price Today:</div>
        <div>US{formatCurrency(amount)}</div>
      </div>
      <div className="flex w-full justify-between border-b border-b-[#E7E7E7] py-[10px] text-18 font-bold text-[#212529]">
        <div>Subtotal:</div>
        <div>US{formatCurrency(0)}</div>
      </div>
      <div className="flex w-full justify-between border-b border-b-[#E7E7E7] py-[10px] text-18 font-bold text-[#7E7E7E]">
        <div>Tax:</div>
        <div>US{formatCurrency(0)}</div>
      </div>

      <div className="flex w-full justify-between py-[10px] text-22 font-bold text-[#212529]">
        <div>Total:</div>
        <div>US{formatCurrency(amount)}</div>
      </div>

      <Tooltip
        label={
          <>
            <div className="rounded-[4px] bg-[#FFFB19] text-sm font-bold capitalize">
              You’ll have access to everything in 0:15 seconds
            </div>
            <div className="mb-[7px] mt-[6px] text-xs">
              Join{" "}
              <span className="text-14 font-bold text-primary">
                390,000 others earning full-time income
              </span>{" "}
              from YouTube!
            </div>
            <div className="rounded bg-[#E9E9E9] py-[2px] text-center font-[900]">
              Backed By A 365-Day Money Back Guarantee
            </div>
          </>
        }
        tooltipLabelClassName="!left-[8px] md:!left-[175px] !top-[15px] after:left-[50px] md:after:left-[20px] before:left-[50px] md:before:left-[20px]  !w-[95%] md:!w-[400px]"
        rootMarginBottom="-50%"
      >
        <div
          className={clsx(
            "button-shine mx-auto mt-[30px] flex cursor-pointer text-center xxxs:h-[73px] md:h-[100px]",
            "items-center justify-center gap-[12px]",
            "rounded-xl text-center",
            "font-black uppercase italic text-white shadow-inner",
            "shadow-xl",
          )}
          style={{
            boxShadow:
              "inset 0 -0 20px rgba(0, 0, 0, 0.1), 0 24px 42px rgba(0,0,0, 0.14)",
            background: "linear-gradient(180deg, #D64439 0%, #D92D20 100%)",
          }}
          onClick={debouncedHandleCreditCard}
        >
          <span className="ml-5 whitespace-nowrap text-[18px] font-[900] md:text-[32px]">
            PLACE MY ORDER
          </span>
          <PointerIcon />
        </div>
      </Tooltip>

      {isProcessing && isMobile && (
        <div className="mt-4 flex items-center justify-center">
          <ThreeDotsLoading />
        </div>
      )}

      {/* <div className="hidden md:inline-block">
        <PaymentDecline show={!!paymentError} paymentError={paymentError} />
      </div> */}

      <div className="mt-5 flex flex-col items-center justify-center">
        <p className="mb-[25px] mt-[10px] w-full text-center text-16 font-medium text-black/70 lg:max-w-full">
          (Please Click Only Once And Allow To Process)
        </p>
        <img
          className="max-w-[325px] md:max-w-[411px]"
          src="/images/checkout/secure-checkout.webp"
          alt="secure-checkout"
          draggable={false}
        />

        <div className="mt-[15px] max-w-[265px] text-center text-16 font-bold-450">
          We securely process payments with 256-bit security encryption
        </div>
      </div>
    </div>
  );
};
