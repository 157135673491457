import ReactGA from 'react-ga4';

export enum GTAGEvent {
  BEGIN_CHECKOUT_FRONT_END = "CUEzCLuv4tEZEPjAwZo-",
  DOWNSELL_PURCHASE = "KolXCLiv4tEZEPjAwZo-",
  UPSELL_PURCHASE = "c_fOCLWv4tEZEPjAwZo-",
  QUIZ_OPT_IN = "SAfdCL6v4tEZEPjAwZo-",
  FRONT_END_PURCHASE = "uzDDCLKv4tEZEPjAwZo-",
}

export function gtag_report_conversion(
  event: GTAGEvent,
  {
    url,
    value,
    transaction_id,
  }: {
    url?: string;
    value?: number;
    transaction_id?: string;
  },
): void {
  try {
    const eventData = {
      category: 'Conversion',
      action: event,
      ...(value && { value: value }),
      ...(transaction_id && { transactionId: transaction_id }),
      nonInteraction: false,
    };

    ReactGA.event(eventData);

    if (url) {
      window.location.href = url;
    }
  } catch (error) {
    console.error('Failed to report conversion:', error);
  }
}


/// <!-- Begin Checkout Front-End --> <script> gtag('event', 'conversion', { 'send_to': 'AW-16698597496/CUEzCLuv4tEZEPjAwZo-', 'value': 3.0, 'currency': 'USD' }); </script>
/// <!-- Downsell Purchase --> <script> gtag('event', 'conversion', { 'send_to': 'AW-16698597496/KolXCLiv4tEZEPjAwZo-', 'value': 97.0, 'currency': 'USD', 'transaction_id': '' }); </script>
/// <!-- Upsell Purchase --> <script> gtag('event', 'conversion', { 'send_to': 'AW-16698597496/c_fOCLWv4tEZEPjAwZo-', 'value': 197.0, 'currency': 'USD', 'transaction_id': '' }); </script>
/// <!-- Quiz Opt-in --> <script> gtag('event', 'conversion', {'send_to': 'AW-16698597496/SAfdCL6v4tEZEPjAwZo-'}); </script>
/// <!-- Front-End Purchase --> <script> gtag('event', 'conversion', { 'send_to': 'AW-16698597496/uzDDCLKv4tEZEPjAwZo-', 'value': 3.0, 'currency': 'USD', 'transaction_id': '' }); </script>
