import { useState, useMemo, useEffect, useCallback } from "react";
import { FlagDetection, FullScreenModal } from "../ui";
import React from "react";
import ProgressBar from "../ui/ProgressBar/ProgressBar";
import { initiateCheckout } from "../../apis/conversion.api";
import { getIPAddress, getOSInfo } from "../../utils/utils";

export const LoaderModal = React.memo(
  ({
    callbackSuccessLoader,
    amount = 0,
    email = ""
  }: {
    callbackSuccessLoader?: () => void;
    amount?: number;
    email?: string;
  }) => {
    const [modal, setShowModal] = useState<boolean>(true);
    const [index, setIndex] = useState<number>(0);

    const textProgress = useMemo(() => {
      return [
        {
          text: "Checking for available spots...",
          loading: { end: 0, duration: 1000 },
        },
        {
          text: "Searching for discounted spots...",
          loading: { end: 50, duration: 8000 },
        },
        {
          text: "Congratulations, your discount has been applied!",
          loading: { end: 70, duration: 2000 },
        },
        {
          text: "Holding a discounted spot for you!",
          loading: { end: 90, duration: 6000 },
        },
        {
          text: "Holding a discounted spot for you!",
          loading: { end: 100, duration: 1000 },
        },
      ];
    }, []);

    const funcPreventTab = useCallback(
      function (event: { key: string; preventDefault: () => void }) {
        if (event.key === "Tab") {
          modal && event.preventDefault();
        }
      },
      [modal],
    );

    const onModalLoading = useCallback(() => {
      window.addEventListener("keydown", funcPreventTab);

      const inputPhone = document.getElementById(
        "input_phone",
      ) as HTMLInputElement;
      if (inputPhone) {
        inputPhone.disabled = true;
      }
    }, [funcPreventTab]);

    const onModalLoaded = useCallback(() => {
      window.removeEventListener("keydown", funcPreventTab);
      const inputPhone = document.getElementById(
        "input_phone",
      ) as HTMLInputElement;
      if (inputPhone) {
        inputPhone.disabled = false;
      }
    }, [funcPreventTab]);

    const handleCheckout = useCallback(async () => {
      const payload = {
        client_ip_address: await getIPAddress(),
        client_user_agent: getOSInfo(),
        email: email,
        value: amount,
      };

      const response = await initiateCheckout(payload);
      console.log("Checkout response:", response);
    }, [amount]);

    useEffect(() => {
      let timer: string | number | NodeJS.Timeout | undefined;
      if (index < textProgress.length) {
        onModalLoading();
        timer = setTimeout(() => {
          setIndex((prev) => prev + 1);
        }, textProgress[index].loading.duration);
      } else {
        callbackSuccessLoader?.();
        setShowModal(false);
        onModalLoaded();
        handleCheckout();
      }

      return () => {
        clearTimeout(timer);
        onModalLoaded();
        onModalLoading();
      };
    }, [
      callbackSuccessLoader,
      handleCheckout,
      index,
      onModalLoaded,
      onModalLoading,
      textProgress,
      textProgress.length,
    ]);

    return (
      <FullScreenModal open={modal}>
        <div className="flex flex-col items-center justify-center">
          <FlagDetection className="flex h-fit max-h-[41px] items-center justify-center text-[63px]" />
          <p className="mt-[25px] text-center text-32 font-black md:mt-[39px] md:text-40">
            {index < textProgress.length
              ? textProgress[index].text
              : textProgress[textProgress.length - 1].text}
          </p>
          <div className="mt-[25px] w-full max-w-[345px] md:max-w-[735px]">
            <ProgressBar stages={textProgress.map((item) => item.loading)} />
          </div>
        </div>
      </FullScreenModal>
    );
  },
);
