import { ComponentPropsWithoutRef, useMemo } from "react";
import { cn } from "../../utils/utils";
import dayjs from "dayjs";
// import Cookies from "js-cookie";
// import { decodeValue } from "../../libs/common";
// import { COOKIE_KEY } from "../../constants";
import useMagicPrice from "../CountDown/useMagicPrice";

export interface SaleOffProps extends ComponentPropsWithoutRef<"p"> {}

const SaleOff = ({ className, ...props }: SaleOffProps) => {
  const { base, original } = useMagicPrice();

  // const salesExpire = Cookies.get(COOKIE_KEY.SALES_EXPIRE)
  //   ? decodeValue(Cookies.get(COOKIE_KEY.SALES_EXPIRE)!)
  //   : "";

  // const isFinishQuiz = Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)
  //   ? decodeValue(Cookies.get(COOKIE_KEY.IS_FINISH_QUIZ)!)
  //   : "";

  const checkoutPriceOptions = `${base}.00`
    // salesExpire === "0" && isFinishQuiz === "0" ? 3.0 : `${base}.00`;

  return (
    <p
      {...props}
      className={cn(
        "flex w-full justify-center text-[20px] text-base font-semibold md:text-xl",
        className,
      )}
    >
      Only ${checkoutPriceOptions}
      &nbsp;
      <span className="text-[#8E8E8E] line-through">${`${original}.00`}</span>
      &nbsp;
      {`as of ${dayjs().format("MMM D")}`}
      {/* {isFinishQuiz === "0" && salesExpire === "0"
        ? `before results expire`
        : `as of ${dayjs().format("MMM D")}`} */}
    </p>
  );
};

export default SaleOff;
