import { useEffect, useState } from 'react';

const useLocalStorageCheck = (key: string) => {
  const [exists, setExists] = useState<boolean>(() => localStorage.getItem(key) !== null);

  useEffect(() => {
    const checkLocalStorage = () => {
      const item = localStorage.getItem(key);
      setExists(item !== null);
    };

    checkLocalStorage();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        checkLocalStorage();
      }
    };

    const handleLocalChange = () => {
      checkLocalStorage(); // Update state in the same tab when localStorage changes
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('localStorageChange', handleLocalChange); // Custom event listener

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('localStorageChange', handleLocalChange);
    };
  }, [key]);

  return exists;
};

export default useLocalStorageCheck;
