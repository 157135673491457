/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import "./wistia.css";
export type VideoProgress = {
  seconds: number;
  duration: number;
  percent: number;
};

type WistiaPlayerProps = {
  id?: string;
  initialValue?: VideoProgress;
  activeVideoIndex?: number;
  isScrollToTop?: boolean;
  className?: string;
  children?: React.ReactNode;
  onWistiaUpdate?: (value: VideoProgress) => void;
  setCloseModal?: () => void;
};

type Video = {
  bind: (event: string, callback: (data: any) => void) => void;
  unbind: (event: string, callback: (data: any) => void) => void;
  remove: () => void;
};

function WistiaPlayer({
  id,
  activeVideoIndex,
  initialValue,
  isScrollToTop = false,
  children,
  className = "",
  onWistiaUpdate,
  setCloseModal,
}: WistiaPlayerProps) {
  const defaultProgress = { seconds: 0, percent: 0, duration: 1 };
  const [videoProgress, setVideoProgress] = useState<VideoProgress>(
    initialValue ?? defaultProgress,
  );

  const [video, setVideo] = useState<Video>();

  const videoRef = useClickOutside<HTMLDivElement>(() => setCloseModal?.());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDurationUpdate = (video: any) => {
    const { duration } = video.data.media;
    setVideoProgress((x) => Object({ ...x, duration }));
  };

  // const handlePercentUpdate = (percent: number) => {
  //   setVideoProgress((x) => Object({ ...x, percent }));
  // };

  // const handleSecondUpdate = (seconds: number) => {
  //   setVideoProgress((x) => Object({ ...x, seconds }));
  // };

  const handleSecondUpdate = (seconds: number) => {
    setVideoProgress((x) => {
      const percent = (seconds / x.duration) * 100;
      return { ...x, seconds, percent };
    });
  };

  const handlePercentUpdate = (percent: number) => {
    setVideoProgress((x) => {
      return { ...x, percent };
    });
  };

  const handleUpdateVideoProgress = () => {
    if (activeVideoIndex) {
      onWistiaUpdate?.(videoProgress);
    }
  };

  const myOnReady = (video: any) => {
    // console.log("I got a handle to the video!", video);
    setVideo(video);
    handleDurationUpdate(video);

    isScrollToTop &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    onWistiaUpdate?.(videoProgress);
  }, [videoProgress]);

  useEffect(() => {
    // Expose functions globally
    window.videoProgress = videoProgress;
    window.setVideoProgress = setVideoProgress;

    // Wistia embed code
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${id}.jsonp`;
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    const div = document.createElement("div");
    div.innerHTML = `
            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                    <div class="wistia_embed wistia_async_${id} seo=false videoFoam=true" playerColor=#d92d20 style="height:100%;position:relative;width:100%">
                        <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                            <img src="https://fast.wistia.com/embed/medias/${id}/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                        </div>
                    </div>
                </div>
            </div>`;

    const container = document.getElementById(wrapper) as HTMLElement;
    container.appendChild(script1);
    container.appendChild(script2);
    container.appendChild(div);

    window._wq = window._wq || [];

    window._wq.push({
      id,
      onReady: myOnReady,
    });

    return () => {
      // Clean up global handlers and remove appended elements
      delete window.videoProgress;
      delete window.setVideoProgress;

      // Cleanup code
      container.innerHTML = "";

      onWistiaUpdate?.(videoProgress);
    };
  }, [id]);

  useEffect(() => {
    if (!video) return;

    video.bind("percentwatchedchanged", handlePercentUpdate);
    video.bind("secondchange", handleSecondUpdate);

    const events = ["play", "pause", "end", "seek"];

    events.forEach((event) => {
      video.bind(event, handleUpdateVideoProgress);
    });

    return () => {
      events.forEach((event) => {
        video.unbind(event, handleUpdateVideoProgress);
      });
      video.remove();
    };
  }, [video]);

  useEffect(() => {
    setVideoProgress(defaultProgress);
  }, [activeVideoIndex]);

  const wrapper = `wistia-player-${id}`;
  return (
    <div ref={videoRef} id={`${wrapper}`} className={className}>
      {children}
    </div>
  );
}

export default WistiaPlayer;
