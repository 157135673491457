import { ComponentPropsWithoutRef } from "react";
import { cn } from "../../utils/utils";
import Sales1 from "./assets/salesGet1.png";
import Sales2 from "./assets/salesGet2.png";
import Sales3 from "./assets/salesGet3.png";
import Sales4 from "./assets/salesGet4.png";
import Sales5 from "./assets/salesGet5.png";

export interface EverythingYouNeedProps
  extends ComponentPropsWithoutRef<"div"> {}

const EverythingYouNeed = ({ className, ...props }: EverythingYouNeedProps) => {
  const featuresList = [
    {
      img: Sales1,
      title: "THE 5-DAY FAST-START",
      description:
        "Launch your profitable faceless channel in just 5 action-packed days. Once you get in, I'll walk you through each step of the system that allows me to earn over $91,000 per month on YouTube - and show you how to duplicate these steps, no matter your situation or experience.",
    },
    {
      img: Sales2,
      title: "100+ CHANNEL IDEAS",
      description:
        "You will get access to Jake's personal <br class='hidden lg:inline' /> list <br class='sm:hidden' /> of the untapped channel ideas <br class='hidden lg:inline' /> that he's eyeballing right now. If Jake was to start <br class='sm:hidden' /> over today, these would <br class='hidden lg:inline' /> be the profitable channel ideas he <br class='hidden lg:inline' /> would start because NO <br class='sm:hidden' /> ONE is <br class='hidden lg:inline' /> doing them ($97 value)",
    },
    {
      img: Sales3,
      title: "CHANNEL PROFIT CALCULATOR",
      description:
        "This plug-and-play channel-profit <br class='sm:hidden' /> calculator helps you predict how <br class='hidden lg:inline' /> your <br class='sm:hidden' /> channel will perform based on <br class='hidden lg:inline' /> data from <br class='sm:hidden' /> Jake's channels.",
    },
    {
      img: Sales4,
      title: "CHANNEL LAUNCH CHECKLIST",
      description:
        "Make sure you don't miss any crucial <br class='sm:hidden' /> elements from this 10+ step checklist <br class='sm:hidden' /> (applies to every channel, regardless <br class='hidden lg:inline' /> of <br class='sm:hidden' /> niche), and don't leave any money <br class='hidden lg:inline' /> on the <br class='sm:hidden' /> table.",
    },
    {
      img: Sales5,
      title: "YOUTUBE FLAG WORDS",
      description:
        "Few people are aware of them, but <br class='hidden lg:inline' /> they <br class='sm:hidden' /> do exist; avoid these niches and <br class='sm:hidden' /> mentioning any of these words, as <br class='hidden lg:inline' /> they <br class='sm:hidden' /> could cause YouTube to stop sending you payments.",
    },
  ];

  return (
    <div
      {...props}
      className={cn(
        "mb-5 flex flex-col items-center justify-center",
        className,
      )}
    >
      <div className="flex w-full max-w-full flex-col gap-[25px] md:max-w-[771px] md:gap-10">
        {featuresList.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-start justify-center gap-7.5 md:flex-row md:items-center md:px-0"
          >
            <img
              src={feature.img}
              alt={feature.title}
              className="h-[198px] w-full rounded-[20px] object-contain md:h-[215px] md:max-w-[303px]"
              loading="lazy"
            />
            <div className="mt-5 flex flex-col gap-[10px] md:mt-0 md:gap-[15px]">
              <p className="mb-2 text-20 font-bold md:mb-0 md:text-28">
                {feature.title}
              </p>
              <p
                className="text-18 font-[400] md:text-26"
                dangerouslySetInnerHTML={{ __html: feature.description }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EverythingYouNeed;
