import axios from "../libs/axios";


export const sendTags = async (payload: { email: string, firstName: string, lastName:string, phone: string, timezone?: string }) => {
  try {
    let clonePayload = {...payload};
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    clonePayload.timezone = tz;
    const res = await axios.post(
      "user/checkout-start",
      clonePayload,
    );
    return res.data;
  } catch (error) {
    console.error("Error making purchase", error);
  }
};
