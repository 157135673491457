import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useEffect,
} from "react";
import { HashLink } from "react-router-hash-link";
import { useStore } from "../../libs/store";
import { cn } from "../../utils/utils";
import AbstractButton from "../Button";
import SaleOff from "./SaleOff";
import { ROUTES } from "../../constants";

export interface SalesButtonProps extends ComponentPropsWithoutRef<"div"> {}

export const SalesButton = forwardRef<ElementRef<"div">, SalesButtonProps>(
  ({ className, ...props }, ref) => {
    const { salesExpire } = useStore();

    useEffect(() => {}, [salesExpire]);

    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          "z-10 inline-flex flex-col items-center justify-center whitespace-nowrap",
          className,
        )}
      >
        <HashLink smooth to={`${ROUTES.SALES}#everythingyouget`}>
          <AbstractButton>
            Get instant access to the FAST-START
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="ml-[10px] size-6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.061 4.93945L10.939 7.06145L15.879 12.0005L10.939 16.9395L13.061 19.0615L20.121 12.0005L13.061 4.93945Z"
                fill="white"
              />
              <path
                d="M6.06096 19.0615L13.121 12.0005L6.06096 4.93945L3.93896 7.06145L8.87896 12.0005L3.93896 16.9395L6.06096 19.0615Z"
                fill="white"
              />
            </svg>
          </AbstractButton>
        </HashLink>

        <SaleOff className="mt-3 md:mt-4" />
      </div>
    );
  },
);

export default SalesButton;
