import { SelectedValue } from "../../../types/type";

// Assuming this will be exported
export const handleProfitCalculation = (selectedValue: SelectedValue): { minYoutubeProfit: number, maxYoutubeProfit: number, minTotalProfit: number, maxTotalProfit: number } => {
    const { sponsorValue, nicheValue, videosPerMonth } = selectedValue;
    
    const minYoutubeProfit = sponsorValue.value * nicheValue.min * videosPerMonth;
    const maxYoutubeProfit = minYoutubeProfit * 2;
    const minTotalProfit = sponsorValue.min + minYoutubeProfit;
    const maxTotalProfit = sponsorValue.max + maxYoutubeProfit;
    
    return {
        minTotalProfit,
        maxTotalProfit,
        minYoutubeProfit,
        maxYoutubeProfit
    }
    
  };
  